import React, { Component } from 'react'

import money from "./../../../../img/otzarHaretz/admin/money.svg";
import bill from "./../../../../img/otzarHaretz/admin/bill.svg";
import { Button } from 'react-bootstrap';

import CashReport from './CashReport';
import CashFullReport from './CashFullReport';

import { ConstantsNames } from "./../../../Urls";

export default class SupplierScreen extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            startDate : '',
            endDate : '',
            reportData : false,
            supplierId : '',
        }
    }
    


    render() {

        let userJ = localStorage.getItem('user-info') ? JSON.parse(localStorage.getItem('user-info')) : '';
        let user = userJ.data ? userJ.data : '';

        //console.log(user);

        var md5 = require('md5');
        let token = md5(user.id + user.phone);
        
        let csvDownload = ConstantsNames.base_url + 'Suppliers/csv?token=' + token
                                                  + '&id=' +  md5(user.id)
                                                  + '&startDate=' + this.state.startDate
                                                  + '&endDate=' + this.state.endDate;

        return (
            <div className="SupplierScreen">

                <div className="m_wrapper">

                    <header>
                        <h1>ברוך הבא<strong>{user.name}</strong></h1>
                    </header>

                    <section className="cashReport">
                        <div className="titleBullet">
                            <img className='bulletAdmin' src={money} alt='' />
                            <h2>תקבולים</h2>
                        </div>
                        <CashReport user={user} />
                    </section>

                    <section className="transactionReport">

                        <div className="titleBullet">
                            <img className='bulletAdmin' src={bill} alt='' />
                            <h2>העברות</h2>
                        </div>

                        {/* data4CsV={(data)=>this.setState({data4CsV : data})} */}
                        {/* user={user} */}
                        <CashFullReport  sendDatesCsv = {(DatesCsv) => this.setState(DatesCsv)} />


                    </section>
                </div>
                
                <div className='sendBtncont'>
                    <Button className="sendBtn" href={ csvDownload }  variant="primary"  size="" target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-paper-plane" />
                        <span>הפקת דוח לתשלום</span>
                    </Button>
                </div>

             
                
            </div>
        )
    }
}
