import React, { Component } from 'react'
//import { ConstantsNames } from "../../Urls"
//import FormConnector from '../../forms/FormConnector';
//import TextToHtml from '../../Texthtml';
//import Container from '@material-ui/core/Container';
//import { NavLink } from 'react-router-dom'; 

//import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */


//import b_facebookIcon from './../../../img/icons/black/facebook.svg';
//import b_instagram from './../../../img/icons/black/instagram.svg';

//import logo from './../../../img/default/logoMenu.svg';

export default class Footer extends Component {


    render() {

      
        //let site = this.props.info.SiteDataitems;
        //let Background = ConstantsNames.pic + site.settings.footer_bg;
        //let PagesNames = this.props.PagesNames;
        //console.log(this.props);

        /* let ProjetsLow = this.props.info.SiteDataitems.ProjetsLow; */
              
        return (
            <div className="footer-wrapper greyBg">
                <footer className="site-footer" role="contentinfo">
                    <div className="credit">
                        <a href="https://waveproject.co.il/" target="_blank" rel="noopener noreferrer">
                            <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
                        </a>
                    </div>
                </footer>
            </div>
        )
    }
}
