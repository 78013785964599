import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

import userInputPic from "./../../../../img/otzarHaretz/userLogin.svg";
import { FormControl, Button, Alert } from 'react-bootstrap';

import loader from "../../../../img/preLoader.gif";

export default class SendPass extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            loading : false,
            mailPass : '',
            userPass : false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
        if(stateNameResponse) {setStateName = stateNameResponse;} else {setStateName = 'data';};

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);

            /* if(url === 'getMoneyUser') {
                
                console.log(getData)
                
            } */

        }));
    }


    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value});
    }

    sendBtn = () => {

        this.setState({ loading : true });

        let url = this.props.admin ? 'resendPassAdmin' : 'resendPass';        

        this.sendtoAdmin( url ,'Users', {email : this.state.mailPass},'userPass');
        //this.props.toStart()
    }
    
     
    render() {
        return (
            <div className="animate__animated animate__fadeInDown SendPass">
                <div className="goBackCont">
                    <button className="goBack" onClick={()=>this.props.toStart()}>&laquo; חזור</button>
                </div>

                <h1>שחזור סיסמה</h1>

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                <div className="inputs">

                    <div className="siteCustomInput picRight">

                        <span><img src={userInputPic} alt='userInputPic' /></span>

                        {this.props.admin ? <FormControl
                                /* className='inputPrepend' */
                                onChange={this.handleChange}
                            /*  className={
                                    ( (this.state.selectedStore &&
                                    this.state.selectedCashier &&
                                    !this.state.moneySend) || this.state.errorCash )
                                    ? 'redBorderError' : ''} */
                                name='mailPass'
                                value={this.state.mailPass}
                                /* disabled={this.props.is_disabled || dataForm.explain === 'ותק שהות' ? true : false} */
                                placeholder="טלפון נייד"
                                type="text"/> :
                                
                                <FormControl
                                /* className='inputPrepend' */
                                onChange={this.handleChange}
                            /*  className={
                                    ( (this.state.selectedStore &&
                                    this.state.selectedCashier &&
                                    !this.state.moneySend) || this.state.errorCash )
                                    ? 'redBorderError' : ''} */
                                name='mailPass'
                                value={this.state.mailPass}
                                /* disabled={this.props.is_disabled || dataForm.explain === 'ותק שהות' ? true : false} */
                                placeholder="אימייל"
                                type="text"/> }
                                    
                    </div>

                    {this.state.userPass && this.state.userPass.error ? <Alert variant="danger"  >
                        <p style={{fontSize: '16px', margin: '0'}}>{this.state.userPass.error}</p>
                    </Alert> : false }

                    {this.state.userPass && this.state.userPass.ok ? <Alert variant="success"  >
                        <p style={{fontSize: '16px', margin: '0'}}>{this.state.userPass.ok}</p>
                    </Alert> : false }

                    {!this.state.userPass || (this.state.userPass && this.state.userPass.error) ? <div className='sendBtncont'>
                        <Button className="sendBtn" disabled={this.state.mailPass || this.state.loading ? false : true} onClick={()=>this.sendBtn()} variant={this.state.mailPass ? "primary" : 'secondary' } size="">
                            <span>שליחה</span>
                        </Button>
                    </div> : false }

                    {this.state.userPass && this.state.userPass.ok ? <div className='sendBtncont'>
                        <Button className="" onClick={()=>this.props.toStart()} variant="dark" size="">
                            <span>&laquo; חזור</span>
                        </Button>
                    </div> : false }

                </div>


            </div>
        )
    }
}
