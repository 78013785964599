import React, { Component } from 'react'
import CountUp from 'react-countup';
import pic1 from '../../../../img/otzarHaretz/system/bullets/1c.png'
import pic2 from '../../../../img/otzarHaretz/system/bullets/2c.png'
import pic3 from '../../../../img/otzarHaretz/system/bullets/3c.png'

import loader from "../../../../img/preLoader.gif";

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

export default class ShowHeaderInfoClient extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            userDataResp : false,
            loading : false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);

            /* if(url === 'reportBuilder') {
                
            } */

        }));
    }


    loadData = () => {
        this.setState({loading : true})
        this.sendtoAdmin('getAllTransactionSystemEditUser','Transactions', {clientId : this.props.dataUser.id },'userDataResp');
    }

    componentDidMount() {
        if(!this.state.userDataResp) {
            this.loadData();
        }
    }
    


    render() {

        return (
            <div className="ShowHeaderInfoClient">

                <img src={loader} alt="loader" style={{right : '58%'}} className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                <section className="Home_top clientsTop">

                    {this.state.userDataResp && this.state.userDataResp.response ? <ul className="clear">
                        
                        <li>
                            <img src={pic1} alt='' />

                            <div className="cont">
                                <h2>יתרת שובר (שווי: <small>₪</small>{this.state.userDataResp.response.allMoney})</h2>
                                <p><small>₪</small><CountUp start={1} separator=',' useEasing={true} startOnMount={true}  decimals={2} end={parseFloat(this.state.userDataResp.response.transactions)} /></p>
                            </div>
                        </li>

                        <li>
                            <img src={pic2} alt='' />

                            <div className="cont">
                                <h2>כמות רכישות</h2>
                                <p><CountUp start={1} separator=',' useEasing={true} startOnMount={true}  decimals={0} end={parseFloat(this.state.userDataResp.response.transactionsCount)} /></p>
                            </div>

                        </li>

                        <li>
                            <img src={pic3} alt='' />

                            <div className="cont">
                                <h2>תאריך הצטרפות</h2>
                                <p>{this.state.userDataResp.response.dateUser}</p>
                            </div>

                        </li>
                    </ul> : false }

                </section>
            </div>
        )
    }
}
