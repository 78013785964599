import React, { Component } from 'react'

import CountUp from 'react-countup';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

export default class CashReport extends Component {


    constructor(props) {

        super(props)
    
        this.state = {
            moneyUser : false
        }

    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            if(url === 'getMoneySupplier' && getData.money) {
                
            }

        }));
    }


    startUp = () => {

        
        //this.setState({loading : true })
        this.sendtoAdmin('getMoneySupplier','Suppliers',{},'moneyUser');

    }
    
    componentDidMount() {
        if(!this.state.moneyUser) {
            this.startUp();
        }
    }


    render() {
        return (
            <div className='CashReportSec clear'>
                
                <div className="quad">
                    <h3>התקבלו היום</h3>
                    <div className="money">
                        <small>₪</small>
                        {this.state.moneyUser && this.state.moneyUser.money ?
                            <CountUp start={1} useEasing={true} startOnMount={true}  decimals={2} end={parseFloat(this.state.moneyUser.money.today)} /> : false }
                    </div>
                    {this.state.moneyUser && this.state.moneyUser.money ? <div className="down">
                        <span>מ-</span> <strong>{this.state.moneyUser.money.uniqueClientsToday}</strong> <span>לקוחות</span>
                    </div> : false }
                </div>

                <div className="quad">
                    <h3>התקבלו החודש</h3>
                    <div className="money">
                        <small>₪</small>
                        {this.state.moneyUser && this.state.moneyUser.money ?
                            <CountUp start={1} useEasing={true} startOnMount={true}  decimals={2} end={parseFloat(this.state.moneyUser.money.thisMonth)} /> : false }
                    </div>
                    {this.state.moneyUser && this.state.moneyUser.money ? <div className="down">
                        <span>מ-</span> <strong>{this.state.moneyUser.money.uniqueClientsMonth}</strong> <span>לקוחות</span>
                    </div> : false }
                </div>

            </div>
        )
    }
}
