import React, { Component } from 'react'

export default class SubscriptionSystem extends Component {
    render() {
        return (
            <div>
                <div className="ClientsList SubscriptionSystem animate__animated animate__fadeIn">

                    <div className="tableCont">
                        <h2 className="systemTitle">ניהול כרטיסים וירטואלים</h2>
                    </div>
                </div>
            </div>
        )
    }
}
