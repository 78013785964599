import React, { Component } from 'react'

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import SuppliersItem from './SuppliersItem';
import loader from "../../../../img/preLoader.gif";

export default class SuppliersList extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            loading: false,
            items : false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            /* if(url === 'getMoneyUser') {
                
                console.log(getData)
                
            } */

        }));
    }


    startUp = () => {

        this.setState({loading : true })
        this.sendtoAdmin('getSuppliers','Suppliers',{},'items');

    }
    
    componentDidMount() {
        if(!this.state.moneyUser) {
            this.startUp();
        }
    }


    render() {
        return (
            <div className="SuppliersList">
                
                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                {this.state.items && this.state.items.suppliers[0] ? <div className="tableSuppliers">
                    {this.state.items.suppliers.map(item => { if (true) {
                            return <SuppliersItem key={item.id} item={item} reload={()=>this.startUp()}  submit={(selectedItem)=>this.props.submit(selectedItem)} />
                    } else return false}) }
                </div> : false }
            </div>
        )
    }
}
