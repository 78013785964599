import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

import ModalDefaul from './../ModalDefaul';
import CashFullReport from './../SupplierScreen/CashFullReport';
import KupotList from './../LoginScreens/KupotList';

import pencil from './../../../../img/otzarHaretz/system/icons/pencil.svg'

import offPic from './../../../../img/otzarHaretz/system/icons/off.svg';
import onPic from './../../../../img/otzarHaretz/system/icons/on.svg';

import { map, find } from 'lodash'

import { FormControl, Button } from 'react-bootstrap';

import ModalSendPassWord from './ModalSendPassWord';

export default class ShowDataClient extends Component {


    constructor(props) {
        super(props)
    
        let dataUser = this.props.client;

        //console.log(dataUser);

        this.state = {

            sendPassmodal : false,

            allTransactions : false,
            onOffUserResp : false,
            clientStatus : this.props.client.status === '1' ? true : false,

            editFields : false,
            editUserResp : false,

            rowId :  dataUser.id,
            name : dataUser.name ? dataUser.name : '',
            agent : dataUser.agent ? dataUser.agent : '',
            phone : dataUser.phone ? dataUser.phone : '',
            email : dataUser.email ? dataUser.email : '',
            tz : dataUser.tz ? dataUser.tz : '',
            ownerName : dataUser.ownerName ? dataUser.ownerName : '',
            address : dataUser.fullAddress ? dataUser.fullAddress : '',
            comments : dataUser.comments ? dataUser.comments : '',
            marketerId : dataUser.marketer.id ? dataUser.marketer.id : '',
            marketerName : dataUser.marketer.name ? dataUser.marketer.name : '',
            pass : ''
        }
    }

    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
    
        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };
    
        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){
    
            //console.log(getData);
            
            if(url === 'onOffSupplier') {
                
                if(getData.ok) {
                    //console.log(getData);
                    this.props.reload();
                    this.setState({ clientStatus : !this.state.clientStatus });
                }
            }

            else if(url === 'editSupplierSystem') {
                
                if(getData.ok) {
                    this.setState({ editFields : false })
                    this.props.reload();
                }

            }
    
        }));
    }




    Onoff = (id) => {

        //console.log(id + '-' + this.state.clientStatus);
        this.sendtoAdmin('onOffSupplier','Suppliers',{ clientId : id, currentStatus : this.state.clientStatus },'onOffUserResp');

        //console.log(id);
        
    }

    saveData = () => {
        
        this.sendtoAdmin('editSupplierSystem','Suppliers',this.state,'editUserResp');

    }
    
    

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }


    sendPassmodal = (actionState) => {
        this.setState({sendPassmodal : actionState});
    }

    handleSelect = (event) => {

        const newName = find(this.props.marketers, function(o) { return o.id === event.target.value });

        //console.log('value', event.target.value);
        
        this.setState({ 
                [event.target.name]: event.target.value,
                marketerName : newName.name }, function(){

            //console.log(this.state)
        });
    
    }


    render() {

        let dataUser = this.props.client;
        let Onoff = !this.state.clientStatus ? {text : 'ספק לא פעיל', picture : offPic } :  {text : 'ספק פעיל', picture : onPic };

        let flagPass;

        if(this.state.pass==='') {

            flagPass = true;

        } else if (this.state.pass.length >= 4 ) {

            flagPass = true;

        } else {

            flagPass = false;

        }


        let flagSave =  flagPass && this.state.name && this.state.agent  && this.state.phone && this.state.address ? true : false;

        return (
            <div className='SelectedClient SupplierSystem animate__animated animate__fadeIn clear' >

                <div className="goBackBtnCont">
                    <button className="goBackBtn bounce" onClick={()=>this.props.clearUser()}>&laquo; חזור</button>
                </div>
                
                <div className={ this.state.clientStatus ? "tableDataUser clear" : "tableDataUser notActive clear" }>

                    <div className="RPrat colors">
                        <h2 className="systemTitle">פרטי ספק<Button className="btnSendPass" onClick={()=>this.sendPassmodal(true)} size="sm" variant="secondary" >שליחת פרטי משתמש</Button></h2>

                        <div className="buttons clear">
                            <button className='editUser' onClick={()=>this.setState({editFields : true})}>
                                <img src={pencil} alt='' />
                                <span>עריכת פרטים</span>
                            </button>

                            <button className='onOff' onClick={()=>this.Onoff(dataUser.id)}>
                                <img src={Onoff.picture} alt='' />
                                <span className={!this.state.clientStatus ? 'red' : ''} >{Onoff.text}</span>
                            </button>
                        </div>

                        <ul className="ulTop">
                            <li className="clear">
                                <span>שם משתמש</span>
                                <strong>{dataUser.username}</strong>
                            </li>

                            <li className="clear">
                                <span>שם ספק</span>
                                {!this.state.editFields ? <strong>{this.state.name}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='name' value={this.state.name} type="text"/> }
                            </li>

                            <li className="clear">
                                <span>איש קשר</span>
                                {!this.state.editFields ? <strong>{this.state.ownerName}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='ownerName' value={this.state.ownerName} type="text"/>}
                            </li>

                            <li className="clear">
                                <span>ת.ז</span>
                                {!this.state.editFields ? <strong>{this.state.tz}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='tz' value={this.state.tz} type="text"/>}
                            </li>

                            <li className="clear">
                                <span>טלפון</span>
                                {!this.state.editFields ? <strong>{this.state.phone}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='phone' value={this.state.phone} type="text"/>}
                            </li>

                            <li className="clear">
                                <span>מייל</span>
                                {!this.state.editFields ? <strong>{this.state.email}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='email' value={this.state.email} type="text"/>}
                            </li>

                            <li className="clear">
                                <span>כתובת</span>
                                {!this.state.editFields ? <strong>{this.state.address}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='address' value={this.state.address} type="text"/> }
                            </li>

                            <li className="clear">
                                <span>שם סוכן</span>
                                {!this.state.editFields ? <strong>{this.state.agent}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='agent' value={this.state.agent} type="text"/>}
                            </li>

                            <li className="clear">
                                <span>משווק</span>
                                {!this.state.editFields ? <strong>{this.state.marketerName}</strong> :
                                
                                <FormControl
                                    style={{
                                        width : '75%',
                                        display: 'inline-block',
                                        margin: '0 8px 0 0'
                                    }}
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend customSelect'
                                    onChange={this.handleSelect} 
                                    value={this.state.marketerId}
                                    name="marketerId"
                                >
                                
                                {   map(this.props.marketers, item => {
                        
                                    return <option key={ item.id } value={ item.id }>{item.name}</option>
                                
                                    })
                                }

                                </FormControl> }
                                
                            </li>

                            <li className="clear">
                                <span>הערות</span>
                                {!this.state.editFields ? <strong>{this.state.comments}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='comments' value={this.state.comments} type="text"/>}
                            </li>

                            {this.state.editFields ? <li className="clear">
                                <span className={!flagPass ? 'redClassInput' : ''} >סיסמה</span>
                                <FormControl className={!flagPass ? 'redClassInput' : ''} placeholder="החלפת סיסמה מעל 4 תווים" onChange={this.handleChange} name='pass' value={this.state.pass} type="text"/>
                            </li> : false }

                            {this.state.editFields ? <li className="clear">
                                <button className={flagSave ? 'send' : 'send sendDisabled'} disabled={ flagSave ? false : true } onClick={()=>this.saveData()}>שמור נתונים</button>
                            </li> : false }

                        </ul>

                        <h2 style={{ margin : '20px 0 20px 0'}} className="systemTitle">קופות</h2>

                        <KupotList user = { { id : this.props.client.id } }/>

                    </div>

                    <div className="LPrat colors">

                        <h2 style={{ margin : '0px 0 20px 0'}} className="systemTitle">פרטי רכישות</h2>
                        <CashFullReport supplierId = {this.props.client.id} />
                        
                    </div>

                </div>


                {this.state.editUserResp &&  this.state.editUserResp.error ? 

                < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.editUserResp.error } } callBack={() => this.setState({ editUserResp : false })}  />

                : false }


                {this.state.editUserResp &&  this.state.editUserResp.ok ? 

                < ModalDefaul variant="" params={ {title: 'תודה', text : this.state.editUserResp.ok } } callBack={() => this.setState({ editUserResp : false })}  />

                : false }

                {this.state.sendPassmodal ?

                <ModalSendPassWord modalShow={true} isClient={false} dataUser = { this.props.client } sendPassmodal = { (action)=>this.sendPassmodal(action) } />

                : false }

            </div>
        )
    }
}
