//import logo from './logo.svg';
import './App.css';

import './css/site.css';
import './css/site_pages.css';
import './css/mobile.css';

//import './css/media.css';
//import './css/accessibility.css';

//https://getbootstrap.com/ //npm install bootstrap@next

import React,{ Component }  from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; //npm install react-router-dom
import LoginPage from "./Components/loginPage"
import LoginPageAdmin from "./Components/loginPageAdmin"
import LoginPageSystem from "./Components/loginPageSystem"
import GetPage from "./Components/getPage"

//import IdleTimer from 'react-idle-timer' //time out timmer
//npm install react-idle-timer --save

export default class App extends Component {
 
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  render() {

      //console.log('Current User: ' + localStorage.getItem('user-info'));
      if (localStorage.getItem('user-info') && localStorage.getItem('user-info').length > 0) {
        return (<React.Fragment>

                  <Router>
                    <Switch>
                      <Route path={"/"} component={GetPage} />
                     
                      </Switch>
                  </Router>
                </React.Fragment>)
      } else {

        return (<React.Fragment>

                <Router>
                  <Switch>
                    <Route path={"/admin"} component={LoginPageSystem} />
                    <Route path={"/supplier"} component={LoginPageAdmin} />
                    <Route path={"/login"} component={LoginPage} />
                    <Route path={"/"} component={LoginPage} />
                  </Switch>
                </Router>

              </React.Fragment>)
      }
      
  }

  handleOnAction (event) {
    //console.log('user did something', event)
  }

  handleOnActive (event) {
    //console.log('user is active', event)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle (event) {
    
    localStorage.setItem('user-info','');
    window.location.reload();

    //console.log('user is idle', event)
    //console.log('last active', this.idleTimer.getLastActiveTime())
  }

}   


