import React, { useState, useEffect } from 'react'
import { RestUrls } from "./Urls"
//import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import {useHistory} from "react-router-dom";
import { Button, FormControl } from 'react-bootstrap';
//import { ConstantsNames } from "./Urls"


/* import NavInner from './../Components/pages/desktop/NavInner'; */

import SendPass from './../Components/pages/desktop/LoginScreens/SendPass';
import ModalDefaul from './../Components/pages/desktop/ModalDefaul';
import logo  from "./../img/otzarHaretz/system/logo.png";
import loginArrow  from "./../img/otzarHaretz/system/icons/loginArrow.svg";

/* import loginPic from "./../img/otzarHaretz/loginPicAdmin.png";
import userLogin from "./../img/otzarHaretz/userLogin.svg";
import lock from "./../img/otzarHaretz/lock.svg";
 */
function Login() {


    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [sendPass, sentSendPassword] = useState();

    const [error, setError] = useState();

    const history = useHistory();

    useEffect(() => {

          /* && localStorage.getItem('user-info').length > 0 */
          let user = (localStorage.getItem('user-info') && localStorage.getItem('user-info').length > 0) ?  true : false;

          if(user) {
            history.push("/") /* ראשי */
          } else {
            history.push("/admin")
          }
          
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    

    async function login() {

      let siteCode = RestUrls.Code;

      //console.log(RestUrls.UserValidation);

      let item = {username,password,siteCode};

      //console.log(item);

      let result = await fetch(RestUrls.UserValidationSystem, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(item)
          });

          result = await result.json();
          /* console.log(result.data); */

          if(result.data) {
              localStorage.setItem('user-info',JSON.stringify(result));
              history.push("/עמוד_הבית"); /* ראשי */
              window.location.reload();
              
          } else if (result.error) {
                setError(result.error)
                history.push("/admin/")
                localStorage.setItem('user-info','');
          } else {
            history.push("/admin/")
            localStorage.setItem('user-info','');
          }
    }


    return (
        
        <div className='body-container loginPage loginPageSystem desktop'>

          
              {sendPass ? <SendPass admin={true} toStart={()=>sentSendPassword(false)} /> : false }

              {!sendPass ? <div>
                {/* animate__animated animate__fadeInDown */}
                <div className="tableSystem">

                  <div className="rightSystem">
                      <img src={ logo } alt='' className="logoClient" />
                  </div>

                  <div className="leftSystem">

                      <div className="loginForm">
                          
                          <header>

                            <h1 className="text-center">התחברות למערכת</h1>
                            <p>התחברו למערכת כדי לראות את כל הדוחות והנתונים באוצר הארץ</p>

                          </header>

                          <div className="inputs">
                          
                            <div className="siteCustomInput">

                                  <FormControl
                                  placeholder="שם משתמש"
                                  aria-label="שם משתמש"
                                  onChange={(e)=>setUsername(e.target.value)}
                                  aria-describedby=""
                                  />

                            </div>

                            <div className="siteCustomInput">
                                  <FormControl
                                  type="password"
                                  placeholder="סיסמה"
                                  aria-label="סיסמה"
                                  onChange={(e)=>setPassword(e.target.value)}
                                  aria-describedby=""
                                  />

                            </div>
                          </div>

                          <div className='loginBtnCont' >
                              <Button className="loginBtn" variant={error ? 'danger' : 'success' } size="lg" onClick={login} ><img src={loginArrow} alt='' />התחברות</Button>
                          </div>

                      </div>
                  </div>

                  {error ? 

                  < ModalDefaul variant="error" params={ {title: 'שגיאה', text : error, option : false } } callBack={() => setError(false)}  />

                  : false }
                      
                </div>

                <div className="credit">
                    <a href="https://www.waveproject.co.il/" target="_blank" rel="noopener noreferrer">
                        <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
                    </a>
                </div>

              </div> : false }
          
        </div>
    )
}
export default Login;