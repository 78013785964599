import React, { Component } from 'react'
import { Button, Accordion, Card  } from 'react-bootstrap';
import ModalMoneyBack from './ModalMoneyBack';

import convertDate from './../../../convertDateToDisplay'

export default class TableReport extends Component {

   
    constructor(props) {
        super(props)
    
        this.state = {
            transaction : false
        }
    }
    

    moneyBack = (transaction) => {
        
        this.setState({ transaction : transaction });
    }


    doneMoney = () => {
        
        this.setState({ transaction : false });
        this.props.reload();
        //console.log('DONE EDIT');

    }
    

    render() {

        //console.log(this.props.reportData.data);

        return (
            
            <div className="TableReport" >
                <div>
                    
                    <div className="table">
                        <div className="tr">
                            <div className="th">תאריך</div>
                            <div className="th">שם הלקוח</div>
                            <div className="th">סכום</div>
                        </div>
                    </div>
                    

                    {this.props.reportData && this.props.reportData.data[0] ? <div className="tr">

                        <div className="td">
                            {this.props.reportData.data.map(item => { if (true) {

                                    let datetimeTransaction = new Date(item.trans.created_at.toString().replace(/-/g, '/')); // IPHONE FIX
                                    var myTime = datetimeTransaction.getHours() + ':' + (datetimeTransaction.getMinutes()<10?'0':'') + datetimeTransaction.getMinutes();

                                    let today = new Date();
                                    let monthTransaction = datetimeTransaction.getMonth() + 1;
                                    let todayMonth = today.getMonth() + 1 ;

                                    let flagRetriveMoney = monthTransaction === todayMonth ? true : false;

                                    return <div key={item.trans.id} className="tableShowTrasaction">
                                                <Accordion key={item.id}>
                                                    <Card>
                                                        <Card.Header className="tableCont">
                                                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                                <div className="tableRow" key={item.id}>
                                                                    <div className="tableTd">{convertDate(item.trans.created_at)}</div>
                                                                    <div className="tableTd">{item.client.name}</div>
                                                                    <div className="tableTd">{parseFloat(item.trans.money)} <small>₪</small></div>
                                                                </div>
                                                            </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body>
                                                                <p>
                                                                    <span>{myTime} | </span>
                                                                    <strong>קופה: </strong><span>{item.cashier.name}</span>
                                                                    <span> | {item.trans.token}</span>
                                                                </p>
                                                                {flagRetriveMoney ? <p style={{padding : '10px 0'}}> <Button onClick={() => this.moneyBack(item) } size="sm" variant="danger" >ביטול עסקה</Button> </p> : false }
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                
                                            </div>
                            } else return false}) }
                        </div>
                    </div> : <div className='tbody' >
                        <div className="tr">
                            <div className='td noData' colSpan="3">לא נמצאו עסקאות</div>
                        </div>
                    </div> }

                </div>

                { this.state.transaction ? <ModalMoneyBack showModal = {this.state.transaction ? true : false} transaction = {this.state.transaction}  callBack={this.doneMoney} /> : false }
                
            </div>
        )
    }
}
