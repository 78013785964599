import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';

export default class ModalDefaul extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            modalShow : true
        }
    }
    
    modalShow = (value) => {
        
        this.setState({ modalShow : value });

        if(this.props.callBack) {
            this.props.callBack();
        }
        
    }

    render() {

        let params = this.props.params;
        let text = params.text;
        let title = params.title;

        return (
            <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite alertModal ' + this.props.variant }
                animation={false}
                onHide={() => this.modalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.state.modalShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body>

                    <Button className="closeBtn" onClick={() => this.modalShow(false)} variant="secondary" >X</Button>

                    <div className="Message">
                        <h2>{title}</h2>
                        <p>{text}</p>
                    </div>

                    {params.option && params.option === 'toSupplier' ? <div className="toSupplier">

                        <h3>בעל/ת עסק?</h3>
                        <a href="/supplier">תקליקו כאן לכניסה</a>

                    </div> : false }

                    {params.option && params.option === 'toClient' ? <div className="toSupplier">

                        <h3>רוצה לקנות בחנות?</h3>
                        <a href="/">תקליקו כאן לכניסה</a>

                    </div> : false }


                    <div className="closeBtnCont">
                        <Button className="closeBtnBig" onClick={() => this.modalShow(false)} >סגירה</Button>
                    </div>

                </Modal.Body>
            </Modal>
        )
    }
}
