import React, { Component } from 'react'



import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/* https://www.npmjs.com/package/react-datepicker */
/* ALL PROPS */
/* https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md */


import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
registerLocale('he', he)

function convertDate (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
}

export default class SearchReport extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    //For datePicker
    handleChangeDate = (date,nameInput) => {

        let newDate = convertDate(date);

        //console.log(newDate);
        //console.log(newDate);

        let returnData = {
            [nameInput] : newDate
        }

        this.props.submit(returnData);

        //this.setState({ [nameInput] :newDate});
    }

    submit = (params) => {
        
    }
    

    render() {


        let today = new Date();
        let startDate = this.props.startDate === ''  ? new Date(today.toString().replace(/-/g, '/')) : new Date(this.props.startDate.toString().replace(/-/g, '/'));  
        let endDate = this.props.endDate === ''  ? new Date(today.toString().replace(/-/g, '/')) : new Date(this.props.endDate.toString().replace(/-/g, '/'));  

        return (
            <div className='SearchReport'>
                <div className="row filterDates">

                    <div className="col-6 cols">
                        <span>מ-</span>
                        <DatePicker 
                            showYearDropdown = {true}
                            selected={startDate}
                            dateFormat='dd/MM/yy'
                            className='form-control'
                            //name='startDate'
                            locale="he"
                            onChange={(date)=>this.handleChangeDate(date, 'startDate')}
                            //disabled={this.props.is_disabled ? true : false}
                        />
                    </div>

                    <div className="col-6 cols">

                        <span style={{marginRight : '2px'}}>ל-</span>
                        
                        <DatePicker 
                            showYearDropdown = {true}
                            selected={endDate}
                            dateFormat='dd/MM/yy'
                            className='form-control'
                            //name='endDate'
                            locale="he"
                            onChange={(date)=>this.handleChangeDate(date, 'endDate')}
                            //disabled={this.props.is_disabled ? true : false}
                        />
                    </div>

                   {/*  <div className="col-2 cols">
                        <div className="filterBtnCont">
                            <Button className="filterBtn" onClick={() => console.log('yes')} >סינון</Button>
                        </div>
                    </div> */}

                </div>
            </div>
        )
    }
}
