import React, { Component } from 'react'

export default class HelpMode extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            step : 1
        }
    }

    changeHelpMode = (step) => {

        //false to close
        //console.log(step);


        this.setState({ step : step }, ()=> {

            if(step) {
                this.props.changeHelpMode(step);
            }
            //console.log(this.state)

        });
        
    }
    
    

    render() {

        let text = '';

        if(this.state.step === 1) {

            text = 'זה הסכום הנותר לניצול החודש';

        }

        else if (this.state.step === 2) {

            text = 'כאן מקישים את הסכום להעברה';

        }

        else if (this.state.step === 3) {

            text = 'רשמו את שם החנות';

        }

        else if (this.state.step === 4) {

            text = 'יש לבחור את שם הקופה בחנות';

        }

        else if (this.state.step === 5) {

            text = 'יש ללחוץ על הכפתור להעברת הכסף';

        }

        let currentStep = parseInt(this.state.step);
        let nextStep = currentStep + 1;
        let prevStep = currentStep -1;

        return (
                <div>
                    {this.state.step ? <div className={`HelpModeComp step${this.state.step} animate__animated animate__fadeIn`} >
                        <div className="blackBg ">
                            <div className="message">
                                
                                <div className="animate__animated animate__heartBeat" >

                                    {currentStep < 5 ? <i className="fas fa-caret-up upMessage"></i> : false }

                                    <div className="textMessage">
                                        <p>{ text }</p>
                                        <button className="nextStepMessageBtn bounce3" onClick={ ()=>this.changeHelpMode( nextStep ) } >הבנתי &raquo;</button>
                                    </div>

                                    {currentStep === 5 ? <i className="fas fa-caret-down upMessage"></i> : false }

                                </div>
                                
                                <ul className="showPoints">
                                    <li className={ this.state.step === 1 ? 'active' : '' }></li>
                                    <li className={ this.state.step === 2 ? 'active' : '' }></li>
                                    <li className={ this.state.step === 3 ? 'active' : '' }></li>
                                    <li className={ this.state.step === 4 ? 'active' : '' }></li>
                                    <li className={ this.state.step === 5 ? 'active' : '' }></li>
                                </ul>
                                

                                <div className="navHelp">

                                    <button disabled={ currentStep === 1 ? true : false } className="navBtn navBtnR" onClick={ ()=>this.changeHelpMode( prevStep ) } >
                                        <i className="fas fa-caret-right"></i>
                                    </button>

                                    <button className="navBtn navBtnClose" onClick={ ()=>this.changeHelpMode(false) }>סגירה</button>

                                    <button disabled={ currentStep === 5 ? true : false } className="navBtn navBtnL" onClick={ ()=>this.changeHelpMode( nextStep ) } >
                                        <i className="fas fa-caret-left"></i>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div> : false }
                </div>
        )
    }
}
