import React, { Component } from 'react'

import ContentWrapper  from './../Components/ContentWrapper';
//import Nav  from './../Components/pages/desktop/Nav';
import NavRight from './../Components/pages/desktop/NavRight'

import NavInner from './../Components/pages/desktop/NavInner'
import UserDetails from "./pages/desktop/LoginScreens/UserDetails"
import UserDetailsAdmin from "./pages/desktop/LoginScreens/UserDetailsAdmin"

import Footer from './../Components/pages/desktop/Footer';

//import Accessibility from './Components/Accessibility';

import './../css/animateNew.css'; /* https://daneden.github.io/animate.css/ */



//import Alert from './../Components/Alert.js';

export default class Desktop extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            showUserData : false,
            showHideHelp : false
        }
    }
    
    showHideHelp = () => {
        


        this.setState({
            showHideHelp : this.state.showHideHelp ? false : 1
        },()=> {
            //console.log(this.state);
        });

    }
    
    render() {

        //let settingsData = this.props.info.SiteDataitems.settings;
        //console.log(this.props.modalTestSite);

        let user = this.props.info.user.data;

        let regularClases = "body-container " + this.props.pageClass;
        let systemClases = "body-container SystemPages " + this.props.pageClass;

        return (
            <div className={ user.userCredential === 'superAdmin' ? systemClases : regularClases }>
                
                {/* <Accessibility  info={this.props.info} accessibility={this.props.accessibility} accesStates={this.props.accesStates}/> */}

                {/* hay mas cosas en SiteConector, getId y en el Alert */}
              {/*   {!this.state.modalTestSite && localStorage.getItem('cancelModalTestSite') !== 'yes' ? 
                    <Alert modalTestSite={true} submit={ this.cancelModalTestSite } title='האתר בהרצה!' message={settingsData.test_site_text} show={true} error={false} siteSettingsData={false} />
                    : false
                } */}
                
                {/* {console.log(this.props.info.user.data)} */}

                {/* { this.props.info.media === 'mobile' ? <Nav page={this.props.page}s info={this.props.info} PagesNames={this.props.PagesNames} className="element" /> : false } */}
                
                <div className={ user.userCredential === 'superAdmin' ? "content-wrapper twoColumns" : "content-wrapper" }>
                    
                    { user.userCredential === 'superAdmin' ? <NavRight page={this.props.page}s info={this.props.info} PagesNames={this.props.PagesNames} className="element" /> : false }

                    {user.userCredential !== 'superAdmin' ? < NavInner user={this.props.info.user.data} showHideHelp={ this.showHideHelp } toggleUserDataPage={()=>this.setState({ showUserData : true })} /> : false }

                    <div className="mainContent">

                        {this.state.showUserData && user.userCredential === 'user' ? <UserDetails toStart={()=>this.setState({ showUserData : false })} /> : false }
                        {this.state.showUserData && user.userCredential === 'admin' ? <UserDetailsAdmin toStart={()=>this.setState({ showUserData : false })} /> : false }

                        {!this.state.showUserData ? <ContentWrapper PagesNames={this.props.PagesNames} showHideHelp={ this.state.showHideHelp } page={this.props.page} info={this.props.info} /> : false }
                        
                        { user.userCredential !== 'superAdmin' ? <Footer /> : false }

                    </div>
                    
                </div>

                {/* <Footer {...this.props}/> */}

            </div>
        )
    }
}
