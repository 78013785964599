import React, { Component } from 'react'
import { Button, Modal, FormControl, Alert } from 'react-bootstrap';
import loader from "../../../../img/preLoader.gif";
import lock from "./../../../../img/otzarHaretz/lock.svg";
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";


export default class ModalSend extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            loading: false,
            cashierPass: '',
            error: false,
            transaction : false
                
        }
    }

    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value});
    }

    checkPass = () => {

        let dataState = this.props.currentState;

        if (dataState.selectedCashier.code === this.state.cashierPass) {

            this.setState({ error : '', loading : true, cashierPass : '' }, function () {
                
                let sendObj = {
                    supplierId : dataState.selectedStore.id,
                    cashierId : dataState.selectedCashier.id,
                    money : dataState.moneySend
                };

                this.sendtoAdmin('makeTransaction','Transactions', sendObj ,'transaction');

            });
            
        } else {
            this.setState({ error : 'קוד קופה אינו נכון, נא לבדוק בקופה של החנות' });
        }
        
    }
    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
        if(stateNameResponse) {setStateName = stateNameResponse;} else {setStateName = 'data';};

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            if(url === 'makeTransaction') {
             
                if(getData.insertToken) {

                    //console.log(getData.insertToken);
                    this.props.endTransaction(getData.insertToken);

                } else {
                    this.setState({ error : 'שגיאה' });
                }
                
            }

        }));
    }

    render() {

        let pay = this.props.currentState;
        let flagSend = this.state.cashierPass && !this.state.loading ? true : false;

        return (
            <Modal
                className='animate__animated animate__fadeInDown animate__faster modalSite'
                animation={false}
                onHide={() => this.props.modalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.props.currentState.modalShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body>

                    <Button className="closeBtn" onClick={() => this.props.modalShow(false)} variant="secondary" >X</Button>

                    <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
                    
                        <div className="sendCash">
                            <header>
                                <h2>העברה על סך <strong>{pay.moneySend}</strong> <small>₪</small></h2>
                                <p>ל- {pay.selectedStore.name}</p>
                            </header>

                            <div className="inputs">

                                <div className="siteCustomInput picRight">

                                    <span><img src={lock} alt='lock' /></span>

                                    <FormControl
                                        name='cashierPass'
                                        type="password"
                                        placeholder="סיסמת הקופה"
                                        aria-label="סיסמה"
                                        onChange={this.handleChange}
                                    />
                                </div>

                                {this.state.error ? <Alert variant="danger"  >
                                <p style={{fontSize: '16px', margin: '0'}}>{this.state.error}</p>
                                </Alert> : false }

                            </div>

                            <div className='sendBtncont'>
                                <Button className="sendBtn" onClick={this.checkPass} disabled={ flagSend ? false : true } variant={ flagSend ? "primary" : "secondary"} size="">
                                    <i className="fas fa-paper-plane" />
                                    <span>העבר</span>
                                </Button>
                            </div>

                        </div>
                </Modal.Body>
            </Modal>
        )
    }
}
