import React, { Component } from 'react'

import ReportMovments from './ReportMovments';
import ReportSupplier from './ReportSupplier';
import { InputGroup, FormControl } from 'react-bootstrap';
import { RestUrls } from "./../../../Urls";

export default class ReportsSystem extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            reportType : ''
        }
    }
    

    handleSelect = (event) => {

        
        this.setState({ [event.target.name]: event.target.value},function(){
            //console.log(this.state)
        });
    
    }

    render() {

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        let csvDownload = RestUrls.baseApiUrl + 'sms/csvSupplierCodesSMSSystem?code=' + RestUrls.Code;

        //console.log(user.userType);

        return (
            <div className="ReportsSystem">
                <h1>דוחות</h1>

                {user.userType !== '2' ? <div>
                    <div className="row justify-content-center textContainer">
                        <div className="col-md-6 divBoostrap col-sm-12">

                            <InputGroup className="customInput">

                                <FormControl
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend customSelect'
                                    onChange={this.handleSelect} 
                                    value={this.state.reportType}
                                    name="reportType"
                                >
                                
                                <option value="">בחרו סוג דו"ח</option>

                                <option value="דוח לפי ספקים">דוח לפי ספקים</option>
                                <option value="דוח תנועות">דוח תנועות</option>
                                <option value="רשימת קודים SMS">רשימת קודים לקנייה ב-SMS לפי ספקים</option>
                                {/* <option value=""></option> */}


                                </FormControl>

                            </InputGroup>

                        </div>
                    </div>

                    {this.state.reportType === 'דוח תנועות' ? <ReportMovments /> : false }
                    {this.state.reportType === 'דוח לפי ספקים' ? <ReportSupplier /> : false }

                    {this.state.reportType === 'רשימת קודים SMS' ? <div style={{textAlign: 'center'}}>
                    
                        <a className='btn btn-secondary' href={ csvDownload } target="_blank" rel="noopener noreferrer">להורדת EXCEL</a>
                    
                    </div> : false }

                    
                </div> :  <ReportSupplier /> }
            </div>
        )
    }
}
