import React, { Component } from 'react'
import { Button, Modal,  FormControl , Form} from 'react-bootstrap';

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";


export default class ModalEditKupot extends Component {

    constructor(props) {
        super(props)

        let data = this.props.rowObj;

        this.state = {

            id : data.id,
            name : this.props.rowObj.name,
            code : data.code,
            phone : data.phone ? data.phone : ''
        }
        
    }
    
   
    
    
    modalShow = () => {
        
        if(this.props.callBack) {
            this.props.callBack();
        }
        
    }

    
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }

    save = () => {

         let sendObj = {
            rowId : this.state.id,
            kupaName : this.state.name,
            kupaPass : this.state.code,
            kupaPhone : this.state.phone
        }

        this.sendtoAdmin('editCashier','Suppliers',sendObj,'editCashierResp');
        
        
    }

    
sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

    let setStateName;

    if(stateNameResponse) {
        setStateName = stateNameResponse;
    } else {
        setStateName = 'data';
    };

    let getData = getDataFromApi(url,objectToSend,controller);
    getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

        //console.log(getData);
        if(url === 'editCashier') {
            
            if(getData.ok) {
                this.props.callBack();
            } else {
                alert('שגיאה');
            }
            

        }

    }));
}



    

    render() {

        let editFlag =  this.state.name && this.state.code.length >= 3 ? true : false;
        

        return (
            <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite alertModal ModalEditKupot' }
                animation={false}
                onHide={() => this.modalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.props.showModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body>

                    <Button className="closeBtn" onClick={() => this.modalShow(false)} variant="secondary" >X</Button>
                    
                    <div className="Message">
                        <h2>עריכה קופה : {this.state.name}</h2>
                        <h3 className="kupotH3">קוד לתשלום ב-SMS: <strong>{this.props.supplierId}.{this.state.id}</strong></h3>

                        <div className="row inputs">

                            <div className="col-6">
                                <Form.Label>שם</Form.Label>
                                <FormControl onChange={this.handleChange} type="text"
                                placeholder='שם' name='name' value={this.state.name} />
                            </div>

                            <div className="col-6">
                                <Form.Label>סיסמה</Form.Label>
                                <FormControl onChange={this.handleChange} type="text"
                                placeholder='סיסמה' name='code' value={this.state.code} />
                            </div>

                            <div className="col-12" style={{margin : '10px 0 25px 0'}} >
                                <Form.Label>טלפון נייד קופה</Form.Label>
                                <FormControl onChange={this.handleChange} type="text"
                                placeholder='טלפון נייד' name='phone' value={this.state.phone} />
                            </div>

                        </div>
                        
                    </div>



                    <div className="row " style = {{ padding : '0 10%'}} >
                        <div className="col-8">
                            <Button disabled={editFlag ? false : true} variant="success" style={{ width : '100%' }} onClick={this.save} >שמור שינוים</Button>
                        </div>
                        
                        <div className="col-4">
                            <Button variant="secondary" style={{ width : '100%' }} onClick={() => this.modalShow(false)} >סגירה</Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        )
    }
}
