import React, { Component } from 'react'
import SearchReport from './SearchReport';
import TableReport from './TableReport';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

export default class CashFullReport extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            startDate : '',
            endDate : '',
            reportData : false,
            supplierId : this.props.supplierId ? this.props.supplierId : '',
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);

            if(url === 'getReportData') {

                //this.props.data4CsV(getData);
                //console.log(getData)
            }

        }));
    }


    submit = (dataObj) => {
        this.setState(dataObj,function() {
            if(this.props.sendDatesCsv) {
                this.props.sendDatesCsv(dataObj);
            }
            this.startUp();
        });
    }

    componentDidMount() {
        if(!this.state.reportData) {
            this.startUp();
        }
    }


    startUp = () => {

        let filters = this.state;

        let sendObj = {
            
            supplierId : filters.supplierId,
            startDate : filters.startDate,
            endDate : filters.endDate

        };

        //console.log(sendObj);

        //this.setState({loading : true })
        this.sendtoAdmin('getReportData','Suppliers',sendObj,'reportData');

    }

    

    render() {
        return (
            <div>

                <SearchReport startDate={this.state.startDate} endDate={this.state.endDate} submit={(dataObj)=>this.submit(dataObj)}/>
                <div className='totalSearch'>סה"כ העברות לפי תאריכים: <strong>{this.state.reportData.summ} <small>₪</small></strong></div>
                {this.state.reportData ? <TableReport reload={()=>this.startUp()} reportData={this.state.reportData} /> : false }

            </div>
        )
    }
}
