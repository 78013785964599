import React, { Component } from 'react';

import {logout} from "./../../logout"
import { Button } from 'react-bootstrap';

//import { ConstantsNames } from "../../Urls"

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from 'react-router-dom'; 

//import bulletMainMenu  from "./../../../img/icons/bulletMainMenu.png";

import logo  from "./../../../img/otzarHaretz/system/logo.png";

import index  from "./../../../img/otzarHaretz/system/icons/index.svg";
import reports  from "./../../../img/otzarHaretz/system/icons/reports.svg";
import sapak  from "./../../../img/otzarHaretz/system/icons/sapak.svg";
import clients  from "./../../../img/otzarHaretz/system/icons/clients.svg";
//import suscription  from "./../../../img/otzarHaretz/system/icons/suscription.svg";



export default class NavRight extends Component {
   

  render() { 
    

    let PagesNames = this.props.PagesNames; //On Site Connector
    //let clientData = this.props.info.SiteDataitems.SystemData;
    let currentPage = this.props.page;

    let userJ = JSON.parse(localStorage.getItem('user-info'));
    let user = userJ.data;

    /* console.log(user); */

    /* console.log(currentPage);
    console.log(PagesNames.home.method); */

    return <div className='site-menu'>
      <div className="mainMenuTable" >
        <div className="fixedPosition">

          {localStorage.getItem('user-info') && localStorage.getItem('user-info').length > 0 ? <Button style={{position : 'absolute', top : '5px', right : '5px'}} className="logOutBtn" onClick={logout} variant="secondary" size="sm"><i className="fas fa-sign-out-alt" /></Button> : '' }
          
          <header>
              {/* <NavLink 
                  role="menuitem" onClick={() => scroll.scrollTo(0)}
                  to={PagesNames.home.url} >
                    <img src={ logo } alt='' className="logoClient" />
              </NavLink> */}
              
              <a href="/עמוד_הבית" target="_self" >
                <img src={ logo } alt='' className="logoClient" />
              </a>
              
          </header>

          <nav className="mainMenuNav">

              {user.userType === '1' || user.userType === '3' ? <div>

              
                  <NavLink 
                      activeClassName={currentPage === PagesNames.home.method ? 'is-active' : ''}
                      role="menuitem" onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.home.url} >
                        <img className='bulletMainMenu' src={index} alt='בולט' />
                        <span>מערכת נתונים</span>
                  </NavLink>

                  <NavLink 
                      activeClassName={currentPage === PagesNames.clients.method ? 'is-active' : ''}
                      role="menuitem" onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.clients.url} >
                        <img className='bulletMainMenu' src={clients} alt='בולט' />
                        <span>{PagesNames.clients.namePage}</span>
                  </NavLink>

                  <NavLink 
                      activeClassName={currentPage === PagesNames.suppliers.method ? 'is-active' : ''}
                      role="menuitem" onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.suppliers.url} >
                        <img className='bulletMainMenu' src={sapak} alt='בולט' />
                        <span>{PagesNames.suppliers.namePage}</span>
                  </NavLink>

                  {/* <NavLink 
                      activeClassName={currentPage === PagesNames.subscription.method ? 'is-active' : ''}
                      role="menuitem" onClick={() => scroll.scrollTo(0)}
                      to={PagesNames.subscription.url} >
                        <img style={{marginRight : '5px'}} className='bulletMainMenu' src={suscription} alt='בולט' />
                        <span>{PagesNames.subscription.namePage}</span>
                  </NavLink> */}

                </div> : false }

                {user.userType !== '3' ? <NavLink 
                    activeClassName={currentPage === PagesNames.reports.method ? 'is-active' : ''}
                    role="menuitem" onClick={() => scroll.scrollTo(0)}
                    to={PagesNames.reports.url} >
                      <img style={{marginRight : '5px'}} className='bulletMainMenu' src={reports} alt='בולט' />
                      <span>{PagesNames.reports.namePage}</span>
                </NavLink> : false }

          </nav>

          <div className="credit">
            <a href="https://waveproject.co.il/" target="_blank" rel="noopener noreferrer">
                <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-w-hd.png" alt="WaveProject" />
            </a>
          </div>
        </div>
      </div>

      
      
      </div>
    
    }
  }
