import React, { Component } from 'react'
//import excel from './../../../../img/otzarHaretz/system/icons/excel.svg'
import { Button } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import { ConstantsNames } from "./../../../Urls";
import loader from "../../../../img/preLoader.gif";

export default class ReportSupplier extends Component {



    constructor(props) {

        super(props)

        this.state = {
            reportData : false,
            loading : false
        }

    }

    componentDidMount() {
        this.startUp();
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        this.setState({loading : true});

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);

            if(url === 'getReportData') {

                //this.props.data4CsV(getData);
                //console.log(getData)
            }

        }));
    }


    submit = (dataObj) => {
        this.setState(dataObj,function() {
            this.startUp();
        });
    }


    startUp = () => {

        this.sendtoAdmin('reportSuppliers','reports',{},'reportData');

    }


    render() {


        let userJ = localStorage.getItem('user-info') ? JSON.parse(localStorage.getItem('user-info')) : '';
        let user = userJ.data ? userJ.data : '';

        //console.log(user);

        var md5 = require('md5');
        let token = md5(user.id + user.phone);
        
        let csvDownload = ConstantsNames.base_url + 'reports/csvReportSupplier?token=' + token
                                                  + '&id=' +  md5(user.id);


        return (
            <div className="ClientsList ReportSupplier">

                <img src={loader} alt="loader" style={{right : '58%'}} className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                {!this.state.loading ? <div className="tableCont">

                    <header className="clear">
                        <h2 className="systemTitle">דוח לפי ספקים</h2>

                        <div className='sendBtncont'>
                            <Button className="sendBtn" href={ csvDownload }  variant="primary"  size="" target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-paper-plane" />
                                <span>הפקת דוח EXCEL</span>
                            </Button>
                        </div>
                    </header>

                    <div className="overflowTable">


                    {this.state.reportData && this.state.reportData.data[0] ? <table className="tableClients">
                        <thead>
                            <tr>
                                <th>שם ספק</th>
                                <th>09/21</th>
                                <th>10/21</th>
                                <th>11/21</th>
                                <th>12/21</th>
                                <th>01/22</th>
                                <th>02/22</th>
                                <th>03/22</th>
                                <th>04/22</th>
                                <th>05/22</th>
                                <th>06/22</th>
                                <th>07/22</th>
                                <th>08/22</th>
                                <th>09/22</th>
                                <th>10/22</th>
                                <th>סה"כ</th>
                            </tr>
                        </thead>

                        <tbody>

                            {this.state.reportData.data.map(item => { if (true) {
                                /* currentState={this.state} */
                                return  <tr key={item.id}>
                                            <td>{ item.name }</td>
                                            <td>{ item.monyMonths["9-21"]}</td>
                                            <td>{ item.monyMonths["10-21"]}</td>
                                            <td>{ item.monyMonths["11-21"]}</td>
                                            <td>{ item.monyMonths["12-21"]}</td>
                                            <td>{ item.monyMonths["1-22"]}</td>
                                            <td>{ item.monyMonths["2-22"]}</td>
                                            <td>{ item.monyMonths["3-22"]}</td>
                                            <td>{ item.monyMonths["4-22"]}</td>
                                            <td>{ item.monyMonths["5-22"]}</td>
                                            <td>{ item.monyMonths["6-22"]}</td>
                                            <td>{ item.monyMonths["7-22"]}</td>
                                            <td>{ item.monyMonths["8-22"]}</td>
                                            <td>{ item.monyMonths["9-22"]}</td>
                                            <td>{ item.monyMonths["10-22"]}</td>
                                            <td><strong>{ item.allMoney}</strong></td>
                                            {/* <td>{parseFloat(item.money)} <small>₪</small></td> */}
                                        </tr>
                            } else return false}) }
                            
                        </tbody>
                    </table> : <h2 style={{ color : 'red', textAlign : 'center', fontSize : '22px' }}>אין תוצאות</h2> }
                </div>
            </div>  : false }
        </div>
        )
    }
}
