import React, { Component } from 'react'
import UserHeaderData from './../UserHeaderData';
import { FormControl } from 'react-bootstrap';

import { Button } from 'react-bootstrap';

import SuppliersList from "./../Suppliers/SuppliersList";
import moneyIsh from "./../../../../img/otzarHaretz/ish.svg";
import moneyInput from "./../../../../img/otzarHaretz/moneyInput.svg";
import SearchAutocompleteAndDbFocus from './../../../DinamicForm/SearchAutocompleteAndDbFocus';
import ModalSend from "./ModalSend";

import EndBuyScreen from './EndBuyScreen';

import HelpMode from './HelpMode';


import getDataFromApi from "./../../../DinamicForm/getDataFromApi";



//import DataSummary from "./DataSummary/DataSummaryIndex";


//import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
//import { NavLink } from 'react-router-dom'; 

export default class HomePage extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            moneySend : '',
            selectedStore : false,
            selectedCashier : false,
            loading: false,
            allCashiers : false,
            modalShow : false,
            moneyUser : 0,
            endBuy : false,
            helpMode : this.props.showHideHelp,
            helpModeStep : 1
        }
    }
    
    

    //set state search and BTN
    handleSearchSelected = (item) => {

        this.setState({selectedStore : item, selectedCashier : false }, function () {
        
            if(item.id) {
                this.sendtoAdmin('getCashiers','Suppliers', {supplierId : item.id},'allCashiers');
            }

            //console.log(item);
            
        });

        
    }

    //SELECT - selectedCashier
    editHandlerSelect = (event) => {

        let id = event.target.value;

        let objFromId = this.state.allCashiers.cashiers.filter(function(desc) {
            return desc.id === id;
        });

        
        this.setState({ selectedCashier : objFromId[0]}, function () {

            //console.log(this.state.selectedCashier);
            
        });
        
    }


    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
        if(stateNameResponse) {setStateName = stateNameResponse;} else {setStateName = 'data';};

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            /* if(url === 'getMoneyUser') {
                
                console.log(getData)
                
            } */

        }));
    }

    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value});
    }

    modalShow = (value) => {

        this.setState({ modalShow : value })
    }

    endTransaction = (insertToken) => {

        if(this.state.helpMode) {

            window.open('/','_self');

        } else {

            this.setState({
                moneySend : '',
                selectedStore : false,
                selectedCashier : false,
                loading: false,
                allCashiers : false,
                modalShow : false,
                endBuy : insertToken,
                errorCash : false,
                helpMode : false            
            });

        }
        

    }

    updateMoneyUser = (money) => {
        this.setState({
            moneyUser : money
        },function() {
            //console.log(this.state);
        });
    }

    send2Modal = () => {
        
        if( parseFloat(this.state.moneySend)  <= parseFloat(this.state.moneyUser) ) {
            
            this.modalShow(true);
            
            
        } else {
            this.setState({ errorCash : true });
        }

    }

    componentDidUpdate () {

       /*  localStorage.setItem('user-info','');
        window.location.reload(); */

        if( this.props.showHideHelp !== this.state.helpMode ) {

            this.setState({
                helpMode : this.props.showHideHelp
            });

        }

    }
    

    render() {

        //console.log(this.props.infoSite.user.data);

        let flagSend = this.state.moneySend &&  parseFloat(this.state.moneySend) > 0 && this.state.selectedCashier && this.state.selectedStore ? true : false;

        let helpModeClass = this.state.helpMode ? 'helpMode helpStep' + this.state.helpModeStep : '';

        return (
            
            <div className={`HomePage animate__animated animate__fadeIn ${helpModeClass}`}>

            
                {!this.state.endBuy ? <div className='userData colorCont'>
                    
                                            
                    <UserHeaderData errorCash = {this.state.errorCash ? true : false} updateMoneyUser={(money) => this.updateMoneyUser(money)} infoSite = {this.props.infoSite} />

                    <div className="whiteCont">

                        <div className="siteCustomInput picRight">

                            <span><img src={moneyInput} alt='money' /></span>

                            <FormControl
                                    /* className='inputPrepend' */
                                    onChange={this.handleChange}
                                    className={
                                        ( (this.state.selectedStore &&
                                        this.state.selectedCashier &&
                                        !this.state.moneySend) || this.state.errorCash )
                                        ? 'redBorderError' : ''}
                                    name='moneySend'
                                    min="0"
                                    /* inputMode="numeric" */
                                    inputMode="decimal"
                                    /* pattern="[0-9]*"  */
                                    pattern="\d*"
                                    value={this.state.moneySend}
                                    /* disabled={this.props.is_disabled || dataForm.explain === 'ותק שהות' ? true : false} */
                                    placeholder="בחרו סכום למימוש ₪"
                                    step=".01"
                                    type="number"/>
                                
                        </div>

                        <div className="twoInputs clear">
                            <div className={this.state.selectedStore ? "siteCustomInput picRight SearchAutocomplete isValue" : "siteCustomInput picRight SearchAutocomplete"} >

                                <span><img src={moneyIsh} alt='money' /></span>
                                <SearchAutocompleteAndDbFocus keys={["name"]} className="a" placeholder={!this.state.selectedStore ? "למי להעביר את הכסף?" : this.state.selectedStore.name } submit={this.handleSearchSelected} DBItems='SiteDataItems?searchDataMenuItems=1' />

                            </div>

                            <div className={this.state.allCashiers && this.state.allCashiers.cashiers[0] ? "siteCustomInput animate__animated animate__flash" : "siteCustomInput"}>
                                
                                <FormControl
                                    disabled = { this.state.allCashiers && this.state.allCashiers.cashiers[0] ? false : true }
                                    as='select'
                                    /* 'inputPrepend customSelect' */
                                    className={this.state.selectedStore && !this.state.selectedCashier ? 'redBorderError' : ''}
                                    onChange={this.editHandlerSelect} 
                                    value={this.state.invoiceAddItem}
                                    name="invoiceAddItem"
                                >

                                <option value="">קופה</option>
                                
                                {this.state.allCashiers && this.state.allCashiers.cashiers[0] ?  <React.Fragment>
                                    {this.state.allCashiers.cashiers.map(item => { if (true) {
                                        return <option key = {item.id} value={item.id}>{item.name}</option>
                                    } else return false}) }
                                 </React.Fragment> : false }

                                </FormControl>
                                
                            </div>

                        </div>

                        <SuppliersList submit={(selectedItem)=>this.handleSearchSelected(selectedItem)}/>

                        

                    </div>

                </div> : <EndBuyScreen insertToken={this.state.endBuy} toStart={()=>this.setState({ endBuy : false })}/>}

                {!this.state.endBuy ? <div className='sendBtncont'>
                    <Button className="sendBtn" onClick={()=>this.send2Modal()} disabled={ flagSend ? false : true } variant={ flagSend ? "primary" : "secondary"} size="">
                        <i className="fas fa-paper-plane" />
                        <span>העבר</span>
                    </Button>
                </div> : false }

                { this.state.helpMode && !this.state.modalShow ? < HelpMode changeHelpMode= { (step) => this.setState({ helpMode : step, helpModeStep : step }) } /> : false }

                { this.state.modalShow ? <ModalSend endTransaction={(insertToken) => this.endTransaction(insertToken)} currentState={this.state} modalShow={(value)=>this.modalShow(value)} /> : false }

            </div>

        )
    }
}
