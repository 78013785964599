import React, { Component } from 'react'
import HomeTop from "./Home_top";
import ClientsList from "./ClientsList"


export default class HomePageSystem extends Component {
    render() {

        let pageData = this.props.pageData;
        //console.log(pageData);

        return (
            <div className='HomePage animate__animated animate__fadeIn'>
                <HomeTop numbers = {pageData.numbers} />

                <ClientsList limit={11} />
            </div>
        )
    }
}
