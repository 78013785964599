import React, { Component } from 'react'
//import excel from './../../../../img/otzarHaretz/system/icons/excel.svg'
import { Button } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import SearchReport from './../SupplierScreen/SearchReport';
import { ConstantsNames } from "./../../../Urls";
import loader from "../../../../img/preLoader.gif";

function convertDateDb (dateValue) {

    if(!dateValue) return '';
    
    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
}

function convertDateTime (dateValue) {

    if(!dateValue) return '';

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX
    /* 2021-03-25 */

/*     let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate(); */

    let formatted_date = current_datetime.getDate() + '/' +
            (current_datetime.getMonth() + 1) + "/" +
            + current_datetime.getFullYear()
            
            + ' ' + current_datetime.getHours() + ':' + (current_datetime.getMinutes()<10?'0':'') + current_datetime.getMinutes() ;
                        
    return formatted_date;
}

export default class ReportMovments extends Component {



    constructor(props) {

        super(props)

        //let today = new Date();
        //let current_datetime = new Date(today.toString().replace(/-/g, '/')); // IPHONE FIX

        this.state = {
            startDate : '',
            endDate : '',
            //startDate : new Date(current_datetime.getFullYear(), current_datetime.getMonth(), 1),
            //endDate : new Date(current_datetime.getFullYear(),current_datetime.getMonth() + 1, 0),
            reportData : false,
            loading : false
        }

    }

    componentDidMount() {
        this.startUp();
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        this.setState({loading : true});

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);

            if(url === 'getReportData') {

                //this.props.data4CsV(getData);
                //console.log(getData)
            }

        }));
    }


    submit = (dataObj) => {
        this.setState(dataObj,function() {
            this.startUp();
        });
    }


    startUp = () => {

        let filters = this.state;

        let sendObj = {
            
            startDate : filters.startDate,
            endDate : filters.endDate

        };

        //console.log(sendObj);

        //this.setState({loading : true })
        this.sendtoAdmin('reportMovments','reports',sendObj,'reportData');

    }


    render() {


        let userJ = localStorage.getItem('user-info') ? JSON.parse(localStorage.getItem('user-info')) : '';
        let user = userJ.data ? userJ.data : '';

        //console.log(user);

        var md5 = require('md5');
        let token = md5(user.id + user.phone);
        
        let csvDownload = ConstantsNames.base_url + 'reports/csvReportMovments?token=' + token
                                                  + '&id=' +  md5(user.id)
                                                  + '&startDate=' + convertDateDb(this.state.startDate)
                                                  + '&endDate=' +  convertDateDb(this.state.endDate);


        return (
            <div className="ClientsList">

                <img src={loader} alt="loader" style={{right : '58%'}} className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                <SearchReport startDate={this.state.startDate} endDate={this.state.endDate} submit={(dataObj)=>this.submit(dataObj)}/>

                {!this.state.loading ? <div className="tableCont">

                    

                    <header className="clear" >
                        <div  className="clear" style={{margin : '0 0 20px 0'}}>
                            <button onClick={()=>this.startUp()} style={{background : 'none', border : 'none', float : 'right', margin : '0 0 0 10px'}}>
                                <i className="fas fa-sync-alt"></i>
                            </button>

                            <h2 className="systemTitle">דוח תנועות</h2>
                        </div>
                        

                        <div className='sendBtncont'>
                            <Button className="sendBtn" href={ csvDownload }  variant="primary"  size="" target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-paper-plane" />
                                <span>הפקת דוח EXCEL</span>
                            </Button>
                        </div>
                    </header>


                    {this.state.reportData && this.state.reportData.data[0] ? <table className="tableClients">
                        <thead>
                            <tr>
                                <th>זיהוי</th>
                                <th>תאריך ושעה</th>
                                <th>שם ספק</th>
                                <th>שם הלקוח</th>
                                <th>אסמכתא</th>
                                <th>שם קופה</th>
                                <th>ב-SMS?</th>
                                <th>סכום</th>
                            </tr>
                        </thead>

                        <tbody>

                            {this.state.reportData.data.map(item => { if (true) {
                                /* currentState={this.state} */
                                return  <tr key={item.id}>
                                            <td>{ item.id }</td>
                                            <td>{ convertDateTime(item.dateTransaction) }</td>
                                            <td>{ item.name}</td>
                                            <td>{ item.nameBuyer }</td>
                                            <td>{ item.token }</td>
                                            <td>{ item.cashier }</td>
                                            <td>{ item.is_SMSBuy === '1' ? 'כן' : '' } </td>
                                            <td>{parseFloat(item.money)} <small>₪</small></td>
                                        </tr>
                            } else return false}) }
                            
                        </tbody>
                    </table> : <h2 style={{ color : 'red', textAlign : 'center', fontSize : '22px' }}>אין תוצאות</h2> }
                    </div> : false }
            </div>
        )
    }
}
