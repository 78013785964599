export default function convertDate (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX
    /* 2021-03-25 */

/*     let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate(); */

    let formatted_date = current_datetime.getDate() + '/' +
            (current_datetime.getMonth() + 1) + "/" +
            + current_datetime.getFullYear();
                        
    return formatted_date;
}