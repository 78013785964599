import React, { Component } from 'react'
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom'; 

//import { Button } from 'react-bootstrap';

import pic1 from '../../../../img/otzarHaretz/system/bullets/1.png'
import pic2 from '../../../../img/otzarHaretz/system/bullets/2.png'
import pic3 from '../../../../img/otzarHaretz/system/bullets/3.png'
import loader from "../../../../img/preLoader.gif";

import addClient from '../../../../img/otzarHaretz/system/icons/addClient.svg'

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

export default class HomeTop extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            moneyResponse : false,
            loading : false
        }
    }

    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);
            /* if(url === 'reportBuilder') {
                
            } */

        }));
    }


    loadData = () => {
        this.setState({loading : true})
        this.sendtoAdmin('getAllMoneysSystemStart','Transactions',{},'moneyResponse');
    }
    


    render() {

        //let number1 = this.props.numbers.number1;
        let number2 = this.props.numbers.number2;
        let number3 = this.props.numbers.number3 ;

        return (
            <section className="Home_top">
                <header className="clear">
                    <h1 className="systemTitle">מערכת נתונים</h1>
                    <NavLink
                        className="addClientBtn btn btn-success" 
                        role="menuitem" 
                        to={'/ניהול_לקוחות'} >
                            <img src={addClient} alt='' />הוספת לקוח
                    </NavLink>

                    {/* <Button className="addClientBtn" variant='success' size="" onClick={()=>console.log('addClient')} ><img src={addClient} alt='' />הוספת לקוח</Button> */}
                </header>
                

                <img src={loader} alt="loader" style={{right : '58%'}} className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                <ul className="clear">
                    <li>
                        <img src={pic1} alt='' />
                        <div className="cont">
                            <h2>רווחים</h2>
                            {this.state.moneyResponse && this.state.moneyResponse.money ? <p><small>₪</small><CountUp start={1} separator=',' useEasing={true} startOnMount={true}  decimals={0} end={parseFloat(this.state.moneyResponse.money)} /></p> : false }
                            {!this.state.moneyResponse ? <p className="showMe">
                                <button onClick={()=>this.loadData()}>להציג &raquo;</button>
                            </p> : false}
                        </div>
                    </li>

                    <li>
                        <img src={pic2} alt='' />

                        <div className="cont">
                            <h2>מנויים</h2>
                            <p><CountUp start={1} separator=',' useEasing={true} startOnMount={true}  decimals={0} end={parseFloat(number2)} /></p>
                        </div>
                    </li>

                    <li>
                        <img src={pic3} alt='' />

                        <div className="cont">
                            <h2>ספקים</h2>
                            <p><CountUp start={1} separator=',' useEasing={true} startOnMount={true}  decimals={0} end={parseFloat(number3)} /></p>
                        </div>

                    </li>
                </ul>

            </section>
        )
    }
}
