import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import thanksPic from "./../../../../img/otzarHaretz/thanksPic.png";


export default class EndBuyScreen extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            data : false
        }
    }
    

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
        if(stateNameResponse) {setStateName = stateNameResponse;} else {setStateName = 'data';};
    
        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){
    
            /* if(url === 'makeTransaction') {
    
            } */
    
        }));
    }

    componentDidMount() {
        this.sendtoAdmin( 'getTransactionData','Transactions', { tokenTrans : this.props.insertToken } );
    }
    
    
    render() {


        let current_datetime = new Date();
        let formatted_date = current_datetime.getDate() + '/' +
                (current_datetime.getMonth() + 1) + "/" +
                + current_datetime.getFullYear()
                + ' ' + current_datetime.getHours() + ':' + (current_datetime.getMinutes()<10?'0':'') + current_datetime.getMinutes();

        return (
            <div className="thanksPage">
                

                {this.state.data && this.state.data.transaction ? <div className="transData">

                    <div className="tokenHeader">
                        <p>מספר אסמכתא:</p>
                        <strong>{this.props.insertToken}</strong>
                    </div>

                    <div className="sendCash">

                        <header>
                            <h2>העברה על סך <strong>{this.state.data.transaction.money}</strong> <small>₪</small></h2>
                            <p>ל- {this.state.data.transaction.name} | קופה: {this.state.data.transaction.cashier}</p>
                            <p>תאריך ושעה: {formatted_date}</p>
                        </header>

                        <h3>הושלמה בהצלחה</h3>

                        <img className="thanksPic" src={thanksPic} alt='' />

                    </div>


                </div> : false }

                {this.state.data && !this.state.data.transaction ? <div className="transData">
                    <h2 style={{color : 'red'}}>שגיאה</h2>
                </div> : false }

                <div className='sendBtncont'>
                    <Button className="sendBtn" onClick={()=>this.props.toStart()} variant="primary" size="">
                        <span>למסך הבית</span>
                    </Button>
                </div>
            </div>
        )
    }
}
