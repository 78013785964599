import React, { Component } from 'react'
//import { NavItem } from 'react-bootstrap'
import smartphone from "./../../../../img/otzarHaretz/smartphone.svg";
import pin from "./../../../../img/otzarHaretz/pin.svg";

import starOn from "./../../../../img/otzarHaretz/starOn.svg";
import starOff from "./../../../../img/otzarHaretz/starOff.svg";

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";



export default class SuppliersItem extends Component {


    sendProp = (item) => {

        scroll.scrollTo(0);
        this.props.submit(item);

    }


    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            //console.log(getData);
            if(url === 'makeFavoriteSuppliers') {
                this.props.reload();  
            }

        }));
    }


    onOffSupplier = () => {
        this.sendtoAdmin('makeFavoriteSuppliers','Suppliers',{ supplierId : this.props.item.id },'favoriteResponse');
    }
    


    render() {

        let item = this.props.item;

        //console.log(item);

        let starUrl = item.favorite ? starOn : starOff ;

        return (
            <div className="tableRow">
                <div className='name tableCell rcell'>
                    <button onClick={()=>this.sendProp(item)}>{item.name}</button>
                </div>
                <div className='star tableCell lcell'>
                    <button onClick={this.onOffSupplier} >
                        <img src={starUrl} alt="star" />
                    </button>
                </div>
                <div className='phone tableCell lcell'>
                    <a href={'tel:' + item.phone} target='_self' rel="noopener noreferrer" >
                        <img src={smartphone} alt="smartphone" />
                    </a>
                </div>
                <div className='wazze tableCell lcell'>
                    <a href={'https://waze.com/ul?q=' + item.address} target='_blank' rel="noopener noreferrer" >
                        <img src={pin} alt="smartphone" />
                    </a>
                </div>
            </div>
        )
    }
}
