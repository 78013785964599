import React, { Component } from 'react'
import ClientsList from "./ClientsList"


export default class ClientsSystem extends Component {

    render() {
        return (
            <div className="ClientsSystem animate__animated animate__fadeIn">

                <h1 className="systemTitle">ניהול לקוחות</h1>

                <ClientsList limit={7} search={true} />
            </div>
        )
    }
}
