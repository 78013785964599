import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

import { FormControl, Button } from 'react-bootstrap';

import ModalDefaul from './../ModalDefaul';
import KupotList from './KupotList';

export default class UserDetailsAdmin extends Component {

    constructor(props) {

        super(props)

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;

        this.state = {
            user : user,

            name : user.name,
            phone : user.phone,
            email : user.email === null ? '' : user.email,
            username : user.username,
            pass: '',
            userPass : false, //response
            page: 'userPage'
        }
     
    }
    
    
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
        if(stateNameResponse) {setStateName = stateNameResponse;} else {setStateName = 'data';};

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            if(url === 'editUserAdmin') {
                if(this.state.userPass.newSession) {
                
                    localStorage.setItem('user-info',JSON.stringify(this.state.userPass.newSession));
    
                }
                //console.log(getData)
            }

        }));
    }

    sendBtn = () => {

        let sendObj = {

            name : this.state.name,
            email : this.state.email,
            username : this.state.username,
            phone : this.state.phone,
            pass : this.state.pass
            
        }

        this.sendtoAdmin('editUserAdmin','Users', sendObj ,'userPass');


        //this.props.toStart()
    }

    closeSession = () => {

        if(this.state.userPass) {

            window.location.href = '/supplier';
            
        } else {

            this.props.toStart();

        }
        

    }
    

    render() {

        //console.log(this.state.user);

        let flagBtn = this.state.name && this.state.username && this.state.phone ? true : false;

        let title = this.state.page === 'userPage' ? 'פרטי משתמש' : 'ניהול קופות';

        return (
            <div className="animate__animated animate__fadeInDown  UserDetails">
                
                <div className="goBackCont paddLR">
                    <button className="goBack" onClick={this.closeSession}>&laquo; חזור</button>
                </div>

                <h1>{title}</h1>

                <nav className="userDetailsNav">
                    <Button className={this.state.page === 'userPage' ? 'active' : ''} onClick={()=>this.setState({page: 'userPage' })} size="" variant="" >אזור אישי</Button>
                    <Button className={this.state.page === 'kupotPage' ? 'active' : ''} onClick={()=>this.setState({page: 'kupotPage' })} size="" variant="" >קופות</Button>
                </nav>

            {this.state.page === 'userPage' ? 
                <div className="inputs paddLR">
                
                <div className="siteCustomInput">

                    <label>שם מלא:</label>
                    <FormControl
                            onChange={this.handleChange}
                            name='name'
                            value={this.state.name}
                            type="text"/>
                </div>

                <div className="siteCustomInput">

                {/* <span><img src={userInputPic} alt='userInputPic' /></span> */}
                <label>טלפון נייד לשליחת SMS: </label>
                <FormControl
                        onChange={this.handleChange}
                        name='phone'
                        value={this.state.phone}
                        type="text"/>
                </div>
                

                <div className="siteCustomInput">

                    {/* <span><img src={userInputPic} alt='userInputPic' /></span> */}
                    <label>אימייל: </label>
                    <FormControl
                            onChange={this.handleChange}
                            name='email'
                            placeholder="מייל"
                            value={this.state.email}
                            type="text"/>
                </div>

                <div className="siteCustomInput">

                    {/* <span><img src={userInputPic} alt='userInputPic' /></span> */}
                    <label>שם משתמש לכניסה למערכת: </label>
                    <FormControl
                            onChange={this.handleChange}
                            name='username'
                            disabled = {true}
                            value={this.state.username}
                            type="text"/>
                </div>

                <div className="siteCustomInput">

                    {/* <span><img src={userInputPic} alt='userInputPic' /></span> */}
                    <label>סיסמה בעלת 5 תווים: אותיות ומספרים:</label>
                    <FormControl
                            onChange={this.handleChange}
                            name='pass'
                            value={this.state.pass}
                            type="text"
                            placeholder='סיסמה חדשה'
                            />
                </div>

                { this.state.userPass && this.state.userPass.error ? 
                    < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.userPass.error} } callBack={()=>this.setState({ userPass : false })}  />
                : false }

                { this.state.userPass && this.state.userPass.ok ? 
                    < ModalDefaul variant="success" params={ {title: 'הודעה', text : this.state.userPass.ok} } callBack=''  />
                : false }
                

                <div className='sendBtncont'>
                    <Button className="sendBtn" disabled={flagBtn ? false : true} onClick={()=>this.sendBtn()} variant={flagBtn ? "primary" : 'secondary' } size="">
                        <span>שליחה</span>
                    </Button>
                </div>

                {this.state.userPass ? <div className="goBackCont bottomCont">
                    <button className="goBack" onClick={this.closeSession}>&laquo; חזור</button>
                </div> : false }
                   

            </div> :

            <KupotList user = { this.state.user }/> }


            </div>
        )
    }
}
