import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import icon from './../../../../img/otzarHaretz/system/i.svg'
import { FormControl } from 'react-bootstrap';

import ShowDataSuppliers from "./SuppliersList_ShowDataSuppliers";

import searchIcon from './../../../../img/otzarHaretz/system/searchIcon.svg'
import excel from './../../../../img/otzarHaretz/system/icons/excel.svg'
import { ConstantsNames, RestUrls } from "./../../../Urls";

import AddSupplier from './AddSupplier'

export default class SuppliersList extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            page : 0,
            dataClients : false,
            startPage : 0,
            selectedClient : false,
            searchUser : ''
        }
    }
    

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
    
        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };
    
        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){
    
            //console.log(getData);

           /*  if(url === 'getClients') {

                if(this.props.updated) {
                    this.props.updated();
                }
                
            } */
    
        }));
    }

    loadData = () => {

        let sendObj = {
            page : this.state.page,
            limit: this.props.limit ? this.props.limit : 8,
            search : this.state.searchUser
        };


        this.sendtoAdmin('getSuppliersSystem','Suppliers',sendObj,'dataClients');

    }

    changePage = (setPage) => {


        this.setState({ page : setPage }, function() {
        
            this.loadData();

        })
    }

    
    changeStartPage = (action) => {

        let changePage;

        if(action === '+') {

            changePage = this.state.startPage + 10  <=  this.state.dataClients.totalPages -1 ? this.state.startPage + 10 : this.state.dataClients.totalPages-1;

        } else if (action === '-') {
            changePage = this.state.startPage - 10  <  0 ? 0 : this.state.startPage - 10;
        }

        

        this.setState({ startPage : changePage }, function() {
        
            this.loadData();

        })
    }
    

    componentDidMount() {

        if(!this.state.dataClients) {
            this.loadData();
        }

    }

    // FOR SEARCH!
    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value, updated : true, page : 0}, function () {
            
            this.loadData();

        });

    }

    makeExcel = () => {
        var md5 = require('md5');
        let token = md5(RestUrls.Code);
        
        let csvDownload = ConstantsNames.base_url + 'Suppliers/suppliersCsv?token=' + token + '&search=' +  this.state.searchUser;

        window.open(csvDownload,'_blank');

    }
    

    render() {

        let userJ = JSON.parse(localStorage.getItem('user-info'));
        let user = userJ.data;
        

        const pages = [];

        let page = this.state.startPage;

       /*  if(this.state.page > 10) {
            page = page -5;
        } */

        if(this.state.dataClients && this.state.dataClients.clients[0]) {


            pages.push(<button key="btnStart" onClick= {()=>this.changeStartPage('-')} >&laquo;</button>);

            for (let i = 1; i <= 10; i++) {

                let pageNumber = page + i;

                if ( pageNumber <= parseInt(this.state.dataClients.totalPages) ) {
                    let setPage = parseInt(pageNumber) - 1;
                        pages.push(<button className={this.state.page + 1 === pageNumber ? 'active' : ''} key={pageNumber} onClick= {()=>this.changePage(setPage)} >{pageNumber}</button>);
                }

            };
            
            pages.push(<button key="btnEnd" onClick= {()=>this.changeStartPage( '+' )} >&raquo;</button>);

        }


        return (
            <div className="ClientsList ">

                { this.props.search && !this.state.selectedClient ?  <div className="searchInputCont">
                    <img src={searchIcon} alt=''/>
                    <FormControl
                    onChange={this.handleChange}
                    name='searchUser'
                    value={this.state.searchUser}
                    type="text"
                    placeholder="חיפוש ספק"
                    className='searchInput siteCustomInput'
                /></div> : false }
                

                {!this.state.selectedClient ? <div className="animate__animated animate__fadeIn">

                    <div className="tableCont">

                        { (this.props.search && this.state?.dataClients ) ? <AddSupplier reload={()=>this.loadData()} marketers = {this.state.dataClients.marketers}  /> : false }
                        
                        <header className="clear">
                            <h2 className="systemTitle">רשימת ספקים</h2>
                            {user.userType !== '3' ? <button onClick={()=>this.makeExcel()}>
                                <img src={excel} alt=""/>
                                <span>ייצוא רשימה</span>
                            </button> : false }
                        </header>
                        

                        {this.state.dataClients && this.state.dataClients.clients[0] ? <table className="tableClients">
                            <thead>
                                <tr>
                                    <th>שם הספק</th>
                                    <th>איש קשר</th>
                                    <th>טלפון</th>
                                    <th>כתובת</th>
                                    <th>סוכן</th>
                                    <th>#</th>
                                </tr>
                            </thead>

                            <tbody>

                                {this.state.dataClients.clients.map(item => { if (true) {
                                    /* currentState={this.state} */
                                    return  <tr key={item.id} className={item.status === '0' ? 'offClientClass' : ''}>
                                                <td>{ item.name }</td>
                                                <td>{ item.ownerName }</td>
                                                <td>{ item.phone }</td>
                                                <td>{ item.address }</td>
                                                <td>{ item.agent }</td>
                                                <td className="infoTd"><button onClick={()=>this.setState({selectedClient : item})}>
                                                    <img src={icon} alt='' />
                                                </button></td>
                                            </tr>
                                } else return false}) }
                                
                            </tbody>
                        </table> : <h2 style={{ color : 'red', textAlign : 'center', fontSize : '22px' }}>אין תוצאות</h2> }
                    </div>

                    {this.state.dataClients && this.state.dataClients.clients[0] ? <div className="pages">

                    {pages}


                    </div> : false }
                </div> : false }

                { this.state.selectedClient ? <ShowDataSuppliers showHeader={this.props.search ? true : false } marketers = {this.state.dataClients.marketers} client={this.state.selectedClient} reload={()=>this.loadData()} clearUser={()=>this.setState({selectedClient : false})} /> : false }

            </div>
        )

    }
}
