import React, { Component } from 'react'
import { Button, Modal, FormControl, Alert} from 'react-bootstrap';

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import convertDate from './../../../convertDateToDisplay'

export default class ModalMoneyBack extends Component {

    constructor(props) {
        super(props)

        let transaction = this.props.transaction;

        //console.log(transaction);

        this.state = {
            ok :false,
            error : false,
            pass : '',
            id : transaction.trans.id,
            supplierId : transaction.cashier.supplierId
        }
        
    }
    
   
    
    
    modalShow = () => {
        
        if(this.props.callBack) {
            this.props.callBack();
        }
        
    }

    
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }

    save = () => {

         let sendObj = {
            rowId : this.state.id,
            pass : this.state.pass,
            supplierId : this.state.supplierId
        }

        this.sendtoAdmin('cancellTransaction','Transactions',sendObj,'cancellTransactionResp');
        
        
    }

    
sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

    let setStateName;

    if(stateNameResponse) {
        setStateName = stateNameResponse;
    } else {
        setStateName = 'data';
    };

    let getData = getDataFromApi(url,objectToSend,controller);
    getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

        //console.log(getData);
        if(url === 'cancellTransaction') {
            
            //console.log(getData);

            if(getData.error) {
                this.setState({error : getData.error})
                
            } else if(getData.ok) {
                this.setState({ error : false, ok : getData.ok })
                //this.props.callBack();
                
            } else {

                alert('שגיאה');

            }
            

        }

    }));
}



    

    render() {

        let sendFlag =  this.state.pass && this.state.pass.length >= 4 ? true : false;


        let transaction = this.props.transaction;

        let datetimeTransaction = new Date(transaction.trans.created_at.toString().replace(/-/g, '/')); // IPHONE FIX
        var myTime = datetimeTransaction.getHours() + ':' + (datetimeTransaction.getMinutes()<10?'0':'') + datetimeTransaction.getMinutes() ;

        return (
            <Modal
                className={ 'animate__animated animate__fadeInDown animate__faster modalSite alertModal ModalEditKupot' }
                animation={false}
                onHide={() => this.modalShow(false)}
                /* size="sm" // בגודל קטן!!! */
                size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.props.showModal}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body>

                    <Button className="closeBtn" onClick={() => this.modalShow(false)} variant="secondary" >X</Button>

                    <div className="Message">

                        

                        <h2>ביטול עסקה {transaction.client.name}</h2>
                        <p><small>₪</small>{transaction.trans.money} | {convertDate(transaction.trans.created_at)} {myTime}</p>
                        

                        {this.state.ok ? <div>
                            <h2  style={{margin: "20px 0 20px 0", fontSize : '26px', color : 'green'}} className='animate__animated animate__bounce'>הביטול בוצע בהצלחה</h2>
                            
                            <div className="row inputs">
                                <div className="col-12">
                                    <Button variant="secondary" style={{ width : '100%' }} onClick={()=>this.props.callBack()} >סגירה</Button>
                                </div>
                            </div>
                            
                            </div>: false }

                        {!this.state.ok ? <div className="row inputs">

                            <div className="col-12">
                                <label>סיסמה:</label>
                                <FormControl onChange={this.handleChange} type="password"
                                placeholder='סיסמת ספק' name='pass' value={this.state.pass} />
                            </div>

                            <br/><br/>

                            <div className="col-12" style={{margin : '10px 0 0 0'}} >
                                <Button disabled={sendFlag ? false : true} variant="danger" style={{ width : '100%' }} onClick={this.save} >ביטול עסקה</Button>
                                <Button variant="secondary" style={{ margin : '5px 0 20px 0', width : '100%' }} onClick={()=>this.props.callBack()} >סגירה</Button>
                            </div>

                            <div className="col-12">
                                {this.state.error ? <Alert variant="danger" >
                                    <p style={{fontSize: '16px', margin: '0'}}>{this.state.error}</p>
                                </Alert> : false }
                            </div>


                        </div> : false }

                        
                        
                    </div>


                </Modal.Body>
            </Modal>
        )
    }
}
