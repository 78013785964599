import React, { Component } from 'react'
import { FormControl, Button } from 'react-bootstrap';
import addClient from '../../../../img/otzarHaretz/system/icons/addClient.svg'
import ModalDefaul from './../ModalDefaul';

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

export default class AddClient extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            firstName : '',
            lastName : '',
            phone : '',
            email : '',
            tz : '',
            city : '',
            address : '',
            comments : '',
            OrderId : '',
            insertClient : false,
            loading : false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
    
        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };
    
        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){
    
            //console.log(getData);
            if(url === 'manualInsertClient') {

                if(getData.response.addLeadtoDb && !getData.response.error ) {

                    this.props.reload();

                    this.setState({
                        firstName : '',
                        lastName : '',
                        phone : '',
                        email : '',
                        tz : '',
                        city : '',
                        address : '',
                        comments : '',
                        OrderId : ''
                    });
                } 

            }
    
        }));
    }



    addClient = () => {

        this.setState({loading : true});

        let sendObj = {
            firstName : this.state.firstName,
            lastName : this.state.lastName,
            phone : this.state.phone,
            email : this.state.email,
            tz : this.state.tz,
            city : this.state.city,
            address : this.state.address,
            comments : this.state.comments,
            OrderId : this.state.OrderId
        }

        this.sendtoAdmin('manualInsertClient','Trumot', sendObj ,'insertClient');
        
    }
    
    


    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }

    handleSelect = (event) => {

        
        this.setState({ [event.target.name]: event.target.value},function(){
            //console.log(this.state)
        });
    
    }

    render() {

        let OrdersName = [
            {
                id : 1,
                name : 'מנוי בשווי ₪650'
            },
            {
                id : 2,
                name : 'מינוי בשווי ₪1300'
            },
            {
                id : 3,
                name : 'מינוי בשווי ₪1800'
            }
        ];

        let flagBtn =   !this.state.loading && this.state.firstName && this.state.lastName && this.state.phone && this.state.email
                        && this.state.tz && this.state.city && this.state.address && this.state.OrderId
                        ? true : false;
        

        return (
            <div className="AddClient">
                <h2>הוספת לקוח</h2>
                <div className="inputs">
                    <div className="row">

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='שם פרטי' name='firstName' value={this.state.firstName} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='שם משפחה' name='lastName' value={this.state.lastName} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='אימייל' name='email' value={this.state.email} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='טלפון' name='phone' value={this.state.phone} />
                        </div>

                    </div>

                    <div className="row">

                        

                        <div className="col-6">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='כתובת' name='address' value={this.state.address} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='עיר' name='city' value={this.state.city} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='תעודת זהות' name='tz' value={this.state.tz} />
                        </div>
                        
                    </div>

                    <div className="row">

                        <div className="col-6">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='הערות' name='comments' value={this.state.comments} />
                        </div>

                        <div className="col-3 ">

                        
                            <FormControl
                                as='select'
                                /* 'inputPrepend customSelect' */
                                className='customSelect'
                                onChange={this.handleSelect} 
                                value={this.state.OrderId}
                                name="OrderId"
                            >
                            
                            <option value="">מנוי</option>

                            {OrdersName.map(item => { if (true) {
                                /* currentState={this.state} */
                                return  <option value={item.id} key={item.id}>{item.name}</option>
                            } else return false}) }
                            

                            </FormControl>

                        </div>

                        <div className="col-3">

                            <Button className='addClientBtn' style={{width : '100%'}} onClick={this.addClient} size="" disabled={ flagBtn ? false : true } variant={flagBtn ? "success" : "secondary" } ><img src={addClient} alt='' />הוספת לקוח</Button>

                        </div>

                    </div>

                </div>

                {this.state.insertClient &&  this.state.insertClient.error ? 

                    < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.insertClient.error } } callBack={() => this.setState({ insertClient : false })}  />

                    : false }
            </div>
        )
    }
}
