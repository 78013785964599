import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import CashiersList from "./CashiersList"
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import ModalDefaul from './../ModalDefaul';

import addKupa from "./../../../../img/otzarHaretz/addKupa.svg";

export default class KupotList extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            cashiers : false,
            kupaName : '',
            kupaPass : '',
            kupaPhone : '',
            supplierId : this.props.user.id ? this.props.user.id : '',
            responseAddcashier : ''
        }
    }
    

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
        if(stateNameResponse) {setStateName = stateNameResponse;} else {setStateName = 'data';};

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            if(url === 'addCashier') {

                if(getData.ok) {
                    this.getCashiers();

                    this.setState({
                        kupaName : '',
                        kupaPass : '',
                        kupaPhone : ''
                    })
                }
                
            } else if(url === 'deletteCashier') {
                this.getCashiers();
                //console.log(getData);
            };
            

        }));
    }

    getCashiers = () => {

        let sendObj = {
            supplierId : this.props.user.id
        }

        this.sendtoAdmin('getCashiers','Suppliers', sendObj ,'cashiers');
        //this.props.toStart()
    }


    componentDidMount() {
        this.getCashiers();
    }
    

    deletteKupa = (rowId) => {
        this.sendtoAdmin('deletteCashier','Suppliers', { rowId : rowId, supplierId : this.state.supplierId } ,'deletteCashierResp');
        //console.log('deletteRow: ' + rowId);

    }

    addKupa = () => {

        let sendObj = {
            kupaName : this.state.kupaName,
            kupaPass : this.state.kupaPass,
            kupaPhone : this.state.kupaPhone,
            supplierId : this.state.supplierId
        }

        this.sendtoAdmin('addCashier','Suppliers', sendObj ,'responseAddcashier');

    }
    

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value}, function () {
            //console.log(this.state);
        });
    }

    render() {

        let kupaPassCheck = this.state.kupaPass.length;

        let flagInsert = this.state.kupaName && (kupaPassCheck >= 3) ? true : false;

        return (
            <div>

                <div className="addKupot ">
                    
                    <div className="row">

                        <div className="col-6 customMargin">

                            <InputGroup className="siteCustomInput">
                               
                                
                                <FormControl
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend'
                                    onChange={this.handleChange} 
                                    value={this.state.kupaName}
                                    name="kupaName"
                                    placeholder="שם קופה"

                                />

                            </InputGroup>

                        </div>
                        

                        <div className="col-6 customMargin btnCont">

                            <InputGroup className="siteCustomInput">
                               
                                
                                <FormControl
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend'
                                    onChange={this.handleChange} 
                                    value={this.state.kupaPass}
                                    name="kupaPass"
                                    placeholder="סיסמה"

                                />

                            </InputGroup>

                            <Button  disabled={flagInsert ? false : true} variant="" className={flagInsert ? '' : 'disableBtn'} onClick={ ()=>this.addKupa()} size="" >
                                <img src={addKupa} alt='' />
                            </Button>

                        </div>

                        

                        
                    </div>

                    <div className="row" style={{ padding : "0 5% 10px 0" }} >

                        <div className="col-12" style={{padding : '0'}}>

                            <InputGroup className="siteCustomInput">
                            
                                
                                <FormControl
                                    /* 'inputPrepend customSelect' */
                                    className='inputPrepend'
                                    onChange={this.handleChange} 
                                    value={this.state.kupaPhone}
                                    name="kupaPhone"
                                    placeholder="טלפון נייד בקופה"

                                />

                            </InputGroup>

                        </div>

                        { this.state.responseAddcashier && this.state.responseAddcashier.error ? 
                            < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.responseAddcashier.error} } callBack={()=>this.setState({ responseAddcashier : false })}  />
                        : false }

                        {/* { this.state.responseAddcashier && this.state.responseAddcashier.ok ? 
                            < ModalDefaul variant="success" params={ {title: 'הודעה', text : this.state.responseAddcashier.ok} } callBack=''  />
                        : false } */}

                    </div>
                </div>

                { this.state.cashiers ? < CashiersList supplierId={this.state.supplierId} deletteMe={ (id)=>this.deletteKupa(id) } reload={()=>this.getCashiers()} cashiers={this.state.cashiers} /> : false }
            </div>
        )
    }
}
