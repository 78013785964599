import React, { Component } from 'react'
import colorCont from "./../../../img/otzarHaretz/top_m.png";

import CountUp from 'react-countup';
//https://www.npmjs.com/package/react-countup

import getDataFromApi from "./../../DinamicForm/getDataFromApi";

//https://www.npmjs.com/package/react-text-truncate

export default class UserHeaderData extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            loading: false,
            moneyUser : false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;

        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            if(url === 'getMoneyUser' && getData.money) {
                
                let money = parseFloat(getData.money);
                this.props.updateMoneyUser(money);

                //console.log(getData)
                
            }

        }));
    }


    startUp = () => {

        this.setState({loading : true })
        this.sendtoAdmin('getMoneyUser','Users',{},'moneyUser');

    }
    
    componentDidMount() {
        if(!this.state.moneyUser) {
            this.startUp();
        }
    }
    


    render() {

        let user = this.props.infoSite.user.data;

        //console.log(this.props.infoSite.SiteDataitems.cash);

        return (
            <div className="firstCont">

                <div className="textCon">
                    <h2>שלום, <strong>{user.name}</strong></h2>
                    <h1 className="regularTypeFamily">אוצר הארץ שומרים שמיטה לכתחילה</h1>
                    <div className="money">
                        <span className="regularTypeFamily">יתרת חשבונך:</span>
                        <strong className={this.props.errorCash ? "redStyle boldTypeFamily" : "boldTypeFamily" }>{ this.state.moneyUser && this.state.moneyUser.money ? 
                        
                        <CountUp start={1} separator=',' useEasing={true} startOnMount={true}  decimals={2} end={parseFloat(this.state.moneyUser.money)} /> : 0 } </strong>
                        <small>₪</small>
                    </div>
                </div>

                <img src={colorCont} alt="bg" />
                
            </div>
        )
    }
}
