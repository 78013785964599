import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import convertDate from './../../../convertDateToDisplay'
import {  Button, Accordion, Card } from 'react-bootstrap';

export default class ShowTransactions extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            allTransactions : false
        }
    }
    

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
        if(stateNameResponse) {setStateName = stateNameResponse;} else {setStateName = 'data';};

        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

            /* if(url === 'addCashier') {

            }  */

        }));
    }

    getTransactions = () => {

        this.sendtoAdmin('getAllUserTransactions','Transactions', {} ,'allTransactions');

    }


    componentDidMount() {
        this.getTransactions();
    }


    render() {


        //console.log(this.state.allTransactions.data);

        return (
            <div className='paddLR'>

                { this.state.allTransactions.data && this.state.allTransactions.data[0] ? <div className="tableShowTrasaction">

                {this.state.allTransactions.data.map(item => { if (true) {

                    let datetimeTransaction = new Date(item.dateTransaction.toString().replace(/-/g, '/')); // IPHONE FIX
                    var myTime = datetimeTransaction.getHours() + ':' + (datetimeTransaction.getMinutes()<10?'0':'') + datetimeTransaction.getMinutes() ;

                    
                    return  <Accordion key={item.id}>
                                    <Card>
                                        <Card.Header className="tableCont">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                <div className="tableRow" key={item.id}>
                                                    <div className="tableTd">{convertDate(item.dateTransaction)}</div>
                                                    <div className="tableTd">{item.name}</div>
                                                    <div className="tableTd"><small>₪</small>{parseFloat(item.money)}</div>
                                                </div>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body>
                                                <p>
                                                    <span>{myTime} | </span>
                                                    <strong>קופה: </strong><span>{item.cashier}</span>
                                                    <span> | {item.token}</span>
                                                    
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                } else return false}) }

                </div> : <h3 style={{color : "red"}} >אין תוצאות</h3> }
            </div>
        )
    }
}
