import React, { Component } from 'react'
import { Button } from 'react-bootstrap';

import delette from "./../../../../img/otzarHaretz/delette.svg";
import edit from "./../../../../img/otzarHaretz/editPencil.svg";

import ModalEditKupot from "./ModalEditKupot";

export default class CashiersList extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            rowObj : false,
            showModal : false
        }
    }
    

    editRow = (rowObj) => {
            
        //console.log(id);
        this.setState({rowObj : rowObj},function () {
            
            
            this.setState({showModal : true});

        });

    }

    doneEdit = () => {
        
        this.setState({ showModal : false, rowObj : false });
        this.props.reload();
        //console.log('DONE EDIT');

    }
    


    render() {

        let allCashiers = this.props.cashiers;
        let count = this.props.cashiers.countCashiers ? parseInt(this.props.cashiers.countCashiers) : 0;

        let flagDelette = count > 1 ? true : false;

        return (
            <div className='CashiersList paddLR'>
                {allCashiers.cashiers ? <ul>
                    {allCashiers.cashiers.map(item => { if (true) {

                        let phone = item.phone ? ' - ' + item.phone : '';

                        /* currentState={this.state} */
                        return  <li key={item.id} className='clear' >
                                <span>קופה {item.name} {phone} <small>({this.props.supplierId}.{item.id})</small></span>
                                <Button  variant="" className={flagDelette ? '' : 'disbledClass' } disabled={flagDelette ? false : true } onClick={ ()=>this.props.deletteMe(item.id) } size="" >
                                    <img src={delette} alt='' />
                                </Button>
                                <Button  variant="" onClick={ ()=>this.editRow(item) } size="" >
                                <img src={edit} alt='' />
                                </Button>
                            </li>
                    } else return false}) }
                </ul> : false }
                <small>* חייב קופה אחת לפחות</small>

                { this.state.rowObj ? <ModalEditKupot supplierId = {this.props.supplierId} showModal = {this.state.showModal} currentState={this.state} rowObj = {this.state.rowObj}  callBack={this.doneEdit} /> : false }
            </div>
        )
    }
}
