import React, { Component } from 'react'

//import {useHistory} from "react-router-dom";

import { Container } from 'react-bootstrap';
import {logout} from "./../../logout"
import { Button } from 'react-bootstrap';

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
//import { NavLink } from 'react-router-dom'; 

import logo from "./../../../img/icons/SVG/logo.png";
import globe from "./../../../img/otzarHaretz/globe.svg";
import phone from "./../../../img/otzarHaretz/phone.svg";
import user from "./../../../img/otzarHaretz/user.svg";

import help from "./../../../img/otzarHaretz/info.svg"

import {getAllUrlParams} from "./../../UrlParameters";

//import SearchAutocompleteAndDbFocus from './../../DinamicForm/SearchAutocompleteAndDbFocus'

//import { Redirect } from "react-router-dom";

/* function ChangeRouter() {
    const history = useHistory();
    history.push("/home")
}
 */

export default class NavInner extends Component {

    
 
    /* handleSearchSelected = (item) => {

        localStorage.setItem('searchInner',JSON.stringify(item));
        let url = item.searchType === 'clients' ? '/לקוחות' : '/עובדים';
        window.location.href = url;
        
    } */
    
    userBtn = () => {
        scroll.scrollTo(0);
        this.props.toggleUserDataPage();
    }
    
    

    render() {


        //console.log(this.props);
        let userJ = localStorage.getItem('user-info') ? JSON.parse(localStorage.getItem('user-info')) : '';
        let userData = userJ.data ? userJ.data : '';

        let urlParams = getAllUrlParams(window.location.search);

        console.log(urlParams);

        return (
            <div className="navCont">
                <div className='NavInner clear'>

                    {/* {this.state.redirect ? <Redirect to={this.state.redirect} /> : false } */}

                    <Container >
                        
                        {/* {console.log(this.props)} */}

                        <img onClick={ () => window.location.reload() } className="mainLogo" src={logo} alt='' />
                        
                        <div className="userCont">
                            {/* <i className="fas fa-user-circle userCircle"></i> */}
                            {/* <span className='userName'>שלום, {this.props.user.username}</span> */}
                            {localStorage.getItem('user-info') && localStorage.getItem('user-info').length > 0 ? <div className='rightCont'>
                                <button onClick={this.userBtn} >
                                    <img className='user' src={user} alt='פרטי_משתמש' />
                                </button>
                                {localStorage.getItem('user-info') && localStorage.getItem('user-info').length > 0 ? <Button className="logOutBtn" onClick={logout} variant="secondary" size="sm"><i className="fas fa-sign-out-alt" /></Button> : '' }
                            </div> : false }
                        </div>

                        <div className="leftCont">

                            { urlParams.help && userData && userData.userCredential === 'user' ? < button className='showHideHelp' onClick={ () => this.props.showHideHelp() } >

                                <img src={help} alt="Site" />

                            </button> : false }

                            <a href="https://site.otzar-haretz.co.il/" target="_blank" rel="noopener noreferrer">
                                <img src={globe} alt="Site" />
                            </a>

                            <a href="tel:*9273" target="_blank" rel="noopener noreferrer">
                                <img src={phone} alt="phone" />
                            </a>
                        
                        </div>
                        
                    </Container>
                </div>

               
            </div>
        )
    }
}
