import React, { Component } from 'react'
import Desktop from './Desktop';
//import Mobile from './Mobile';


export default class SiteConnector extends Component {
   
    constructor(props) {
        
        //console.log(props.pictures)

        super(props);

        this.state = {
            modalTestSite: false,
            accessibilityClass: 'noneCss',
            zoomClass: 'noneZoom',
            linksClass: 'noLInks',
            fontsClass: 'noFonts',
        }
    }

    async componentDidMount() {
       
        if (localStorage.getItem('accessibilityClass')) {
            this.setState({accessibilityClass: localStorage.getItem('accessibilityClass')})
        }

        if (localStorage.getItem('zoomClass')) {
            this.setState({zoomClass: localStorage.getItem('zoomClass')})
        }

        if (localStorage.getItem('linksClass')) {
            this.setState({linksClass: localStorage.getItem('linksClass')})
        }

        if (localStorage.getItem('fontsClass')) {
            this.setState({fontsClass: localStorage.getItem('fontsClass')})
        }
    }

    cancelModalTestSite = (data) => {

        this.setState(data, () => {
            //console.log(this.state);
          }); 

        localStorage.setItem('cancelModalTestSite', 'yes');
        

    }

    changeAccessibilityClass = (data) => {
        if(data) {

            var changeClass='';

            //if(this.state.accessibilityClass.includes("accessibility")) {

            //console.log(this.state);

            if(data === 'accessibility') {
                if(this.state.accessibilityClass === "accessibility") {
                    changeClass = data.replace("accessibility", " ");
                }

                else {
                    changeClass = 'accessibility';
                }

                this.setState({accessibilityClass: changeClass})
                localStorage.setItem('accessibilityClass', changeClass);
            }


            else if(data === 'zoomIn') {
                if(this.state.zoomClass === "zoomIn") {
                    changeClass = data.replace("zoomIn", " ");
                }

                else {
                    changeClass = 'zoomIn';
                }

                this.setState({zoomClass: changeClass})
                localStorage.setItem('zoomClass', changeClass);
            }


            else if(data === 'linksOn') {
                if(this.state.linksClass === "linksOn") {
                    changeClass = data.replace("linksOn", " ");
                }

                else {
                    changeClass = 'linksOn';
                }

                this.setState({linksClass: changeClass})
                localStorage.setItem('linksClass', changeClass);
            }

            else if(data === 'fontsOn') {
                if(this.state.fontsClass === "fontsOn") {
                    changeClass = data.replace("fontsOn", " ");
                }

                else {
                    changeClass = 'fontsOn';
                }

                this.setState({fontsClass: changeClass})
                localStorage.setItem('fontsClass', changeClass);
            }
            
            //console.log(data);
        }
        
    }
    
    render() {

        
        let page = this.props.page;

        

        let pageClass = page.replace('/',"_");

        let media = this.props.info.media === 'mobile' ? 'mobile' : 'desktop';

        //console.log(this.props.info.SiteDataitems.pages);

        let PagesNames = {

            home: {
              url:'/' + this.props.info.SiteDataitems.pages[0].seo.friendly,
              namePage : this.props.info.SiteDataitems.pages[0].title,
              method : this.props.info.SiteDataitems.pages[0].seo.method
            },

            clients: {
                url:'/' + this.props.info.SiteDataitems.pages[1].seo.friendly,
                namePage : this.props.info.SiteDataitems.pages[1].title,
                method : this.props.info.SiteDataitems.pages[1].seo.method
              },

            suppliers: {
                url:'/' + this.props.info.SiteDataitems.pages[2].seo.friendly,
                namePage : this.props.info.SiteDataitems.pages[2].title,
                method : this.props.info.SiteDataitems.pages[2].seo.method
              },

            reports: {
                url:'/' + this.props.info.SiteDataitems.pages[3].seo.friendly,
                namePage : this.props.info.SiteDataitems.pages[3].title,
                method : this.props.info.SiteDataitems.pages[3].seo.method
              },

            subscription: {
                url:'/' + this.props.info.SiteDataitems.pages[4].seo.friendly,
                namePage : this.props.info.SiteDataitems.pages[4].title,
                method : this.props.info.SiteDataitems.pages[4].seo.method
              }
            
        }

        //console.log(this.props);

        if (media === 'mobile' & false ) {
            return <h1>DESKTOP ONLY</h1>
        }

        else {
            return <Desktop {...this.props}

            submit={ this.cancelModalTestSite }
            modalTestSite={this.state.modalTestSite}

            PagesNames = {PagesNames}
            accesStates={this.state}
            pageClass={
                pageClass + ' ' + media + ' '  +
                this.state.accessibilityClass + ' ' +
                this.state.zoomClass +  ' ' +
                this.state.linksClass +  ' ' +
                this.state.fontsClass 
            }  accessibility={this.changeAccessibilityClass} />
        }
    }
}
