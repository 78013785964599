import React, { Component } from 'react'
//import { Modal } from 'react-bootstrap';

import { RestUrls } from "../Components/Urls";

import HomePage  from './pages/desktop/HomePage/HomePage';
import SupplierScreen  from './pages/desktop/SupplierScreen/SupplierScreen';

import HomePageSystem from './pages/desktop/SystemPages/HomePageSystem';
import ClientsSystem from './pages/desktop/SystemPages/ClientsSystem';
import SuppliersSystem from './pages/desktop/SystemPages/SuppliersSystem';
import ReportsSystem from './pages/desktop/SystemPages/ReportsSystem';
import SubscriptionSystem from './pages/desktop/SystemPages/SubscriptionSystem';

//import Clients  from './pages/desktop/Clients/Clients';

import Page404  from './pages/Page404';


export default class ContentWrapper extends Component {


    constructor(props) {
        super(props);
        
        this.state = {
          items: [],
          isLoaded: false
        }

        
      }


      async componentDidMount() {
        
        //const pageName = RestUrls.Pages + this.props.page;
        const pageName = RestUrls.Pages + this.props.page;
        
        //console.log(pageName);

        let API = pageName;
        let user = this.props.info.user.data;

        //console.log(API);

        //console.log(this.props.page);

        let sitePass = { 
            siteCode :  RestUrls.Code,
            userId : user.id,
            userName : user.username,
            userCredential : user.userCredential,
            token : user.token
        };

        //console.log(sitePass);

        const res = await fetch(API, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(sitePass)
          });

        const data = await res.json();

        this.setState(
            {items: data,  isLoaded: true,}

        )
    }

      render() {

        //console.log(this.props.page);

        let {isLoaded, items } = this.state;

        //console.log(paramWorker);

        //let closeButton = <button className="Xbtn">X</button>
        //console.log(items);

        if (!isLoaded) {
            return <div>טוען...</div>
        }

        else if(this.props.info.user.data.userCredential === 'superAdmin' && this.props.info.user.data.userType === '2') {

            return (
                <React.Fragment>
                    <ReportsSystem pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                </React.Fragment>
            )

        }

        else if(this.props.info.user.data.userCredential === 'superAdmin') {

            switch (this.props.page) {

                case "index":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* {console.log(this.props.info.user)} */}
                            <HomePageSystem pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )

                case "clients":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* {console.log(this.props.info.user)} */}
                            <ClientsSystem pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )


                case "suppliers":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* {console.log(this.props.info.user)} */}
                            <SuppliersSystem pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )


                case "reports":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* {console.log(this.props.info.user)} */}
                            {this.props.info.user.data.userType !== '3' ? <ReportsSystem pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} /> : <h1>אזור לא מורשה למשתמש זה</h1> }
                        </React.Fragment>
                    )


                case "subscription":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* {console.log(this.props.info.user)} */}
                            <SubscriptionSystem pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                        </React.Fragment>
                    )



                case "welcome/error404Page":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            <Page404 />
                        </React.Fragment>
                    )

                default:
                    return (
                        <div>DEFAULT CASE  Page1</div>
                    )
            }



        }

        else {

        
            switch (this.props.page) {

                case "index":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            {/* {console.log(this.props.info.user)} */}
                            
                            {this.props.info.user.data.userCredential === 'admin' ? 
                                    <SupplierScreen pageData={items} PagesNames={this.props.PagesNames} infoSite={this.props.info} />
                                    : <HomePage pageData={items} showHideHelp={ this.props.showHideHelp } PagesNames={this.props.PagesNames} infoSite={this.props.info} /> }
                        </React.Fragment>
                    )

                case "welcome/error404Page":
                    return (
                        <React.Fragment>
                            {/* <Seo infoSite={this.props.info} pageData={items} /> */}
                            <Page404 />
                        </React.Fragment>
                    )

                default:
                    return (
                        <div>DEFAULT CASE  Page1</div>
                    )
            }

        }
    }
}
