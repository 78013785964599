import React, { useState, useEffect } from 'react'
import { RestUrls } from "./Urls"
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import {useHistory} from "react-router-dom";
import { Button, FormControl } from 'react-bootstrap';
//import { ConstantsNames } from "./Urls"


import NavInner from './../Components/pages/desktop/NavInner';
import ModalDefaul from './../Components/pages/desktop/ModalDefaul';
import SendPass from './../Components/pages/desktop/LoginScreens/SendPass';

import loginPic from "./../img/otzarHaretz/loginPic.png";
import userLogin from "./../img/otzarHaretz/userLogin.svg";
import lock from "./../img/otzarHaretz/lock.svg";

import { getAllUrlParams } from "./UrlParameters"

function Login() {

    

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [sendPass, sentSendPassword] = useState();

    const [error, setError] = useState();

    const history = useHistory();

    
    const urlGET = getAllUrlParams();
    if(urlGET.username && !username) {
        setUsername(urlGET.username);
    }

    


    

    useEffect(() => {

          /* && localStorage.getItem('user-info').length > 0 */
          let user = (localStorage.getItem('user-info') && localStorage.getItem('user-info').length > 0) ?  true : false;

          if(user) {
            window.location.reload(); /* ראשי */
          } else {
            //history.push("/login")
          }
          
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    

    async function login() {

      let siteCode = RestUrls.Code;

      //console.log(RestUrls.UserValidation);

      let item = {username,password,siteCode};

      //console.log(item);

      let result = await fetch(RestUrls.UserValidation, {
            method: 'POST',
            headers: {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json'
            },
            body: JSON.stringify(item)
          });

          result = await result.json();
          /* console.log(result.data); */

          if(result.data) {
              localStorage.setItem('user-info',JSON.stringify(result));
              history.push("/"); /* ראשי */
              window.location.reload();
              
          } else if (result.error) {
                setError(result.error)
                history.push("/")
                localStorage.setItem('user-info','');
          } else {
            history.push("/")
            localStorage.setItem('user-info','');
          }
    }


    return (
        <div className={isMobile ? 'body-container loginPage mobile' : 'body-container loginPage desktop'}>

              <header>
                <NavInner user={false}  />
              </header>

              {sendPass ? <SendPass admin={false} toStart={()=>sentSendPassword(false)} /> : false }

              {!sendPass ? <div>
                {/* animate__animated animate__fadeInDown */}
                <div className="container container-fluid ">
                

                  <div className="row justify-content-center textContainer">

                    <div className="col-lg-4">
                        <h1 className="text-center">ברוכים הבאים למערכת התשלומים של <strong>אוצר הארץ</strong></h1>

                        <img src={loginPic} className='loginPic' alt='' />

                        <h2 className="text-center">התחברות</h2>

                          <div className="siteCustomInput picRight">

                                <span><img src={userLogin} alt='userLogin' /></span>
                                
                                <FormControl
                                placeholder="שם משתמש"
                                aria-label="שם משתמש"
                                onChange={(e)=>setUsername(e.target.value)}
                                aria-describedby=""
                                value={username ? username : ''}
                                />
                          </div>
                              

                          <div className="siteCustomInput picRight">

                                <span><img src={lock} alt='lock' /></span>

                                <FormControl
                                type="password"
                                placeholder="סיסמה"
                                aria-label="סיסמה"
                                onChange={(e)=>setPassword(e.target.value)}
                                aria-describedby=""
                                />

                          </div>

                            {error ? < ModalDefaul variant="error" params={ {title: 'שגיאה', text : error, option : 'toSupplier'} } callBack={() => setError(false)}  />
                            : false }

                            <div className='loginBtnCont' >
                              <Button className="loginBtn" variant={error ? 'danger' : 'success' } size="lg" onClick={login} >התחברות</Button>
                            </div>

                            <div className="resetPass">
                              <span>שכחת סיסמה? </span>
                              <Button variant='outline-primary' onClick={()=>sentSendPassword(true)} >לחצו כאן</Button>

                              <span>| בעל חנות? </span>
                              <Button variant='outline-primary' href='/supplier' target="_self">לחצו כאן</Button>
                            </div>

                            {/* <div className="newUser">
                              <span>עדין לא רשומים?</span>
                              <Button variant='outline-primary' onClick={()=>console.log('newUser')} >לחצו כאן</Button>
                            </div> */}
                            
                    </div>
                    
                  </div>
                </div>

                <div className="credit">
                    <a href="https://www.waveproject.co.il/" target="_blank" rel="noopener noreferrer">
                        <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
                    </a>
                </div>

              </div> : false }

            
          
        </div>
    )
}
export default Login;