import React, { Component } from 'react'
import { Button, Modal} from 'react-bootstrap';
import loader from "../../../../img/preLoader.gif";
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";


export default class ModalSendPassWord extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            loading : false,
            response : false
        }
    }


    sendPass = () => {

        this.setState({loading : true});

        let sendObj = {
            id : this.props.dataUser.id,
            userType : this.props.isClient ? 'client' : 'supplier'
        };

        this.sendtoAdmin('sendUserPassToUsers','sms', sendObj ,'response');
        
    }
    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

            let setStateName;
            if(stateNameResponse) {setStateName = stateNameResponse;} else {setStateName = 'data';};

            let getData = getDataFromApi(url,objectToSend,controller);
            getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){

        }));
    }

    render() {

        return (
            <Modal
                className='animate__animated animate__fadeInDown animate__faster modalSite'
                animation={false}
                onHide={() => this.props.sendPassmodal(false)}
                //size="sm" // בגודל קטן!!!
                //size="lg" // גדול!! */
                // backdrop="static" // חייב ללחוץ על כפתור!
                /* centered // VERTICA CENTER */
                show={this.props.modalShow}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                
                <Modal.Body>

                    <Button className="closeBtn" onClick={() => this.props.sendPassmodal(false)} variant="secondary" >X</Button>

                    <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
                    
                        {this.state.response ? <div className="sendPassToUser">
                            <h2  style={{margin: "20px 0 20px 0", textAlign: 'center', fontSize : '26px', color : 'green'}} className='animate__animated animate__bounce'>הפרטים נשלחו בהצלחה</h2>
                            
                            <div className="contentModal">
                                <Button onClick={() => this.props.sendPassmodal(false)} variant="secondary" >
                                    <span>סגירה</span>
                                </Button>
                            </div>
                        </div> :

                        <div className="sendPassToUser">

                            {this.props.isClient ? <header>
                                <h2>שליחת פרטי משתמש ללקוח: <br/><strong>{this.props.dataUser.firstName} {this.props.dataUser.lastName}</strong></h2>
                                <p className="redP">לשלוח שם משתמש וסיסמה חדשה לטלפון ולמייל?</p>
                                {/* <p>לטלפון: {this.props.dataUser.phone}<br/>ולמייל: {this.props.dataUser.email} </p> : <p>asdasdasd</p> */}
                            </header> :
                            <header>
                                <h2>שליחת פרטי משתמש לחנות: <br/><strong>{this.props.dataUser.name} <br/> ח"פ: {this.props.dataUser.tz}</strong></h2>
                                <p className="redP">לשלוח שם משתמש וסיסמה חדשה לטלפון?</p><br/>
                                {/* <p>לטלפון: {this.props.dataUser.phone}</p> */}
                            </header>
                            }

                            <div className="contentModal">

                                <Button onClick={this.sendPass} disabled={ this.state.loading ? true : false } variant={ !this.state.loading ? "primary" : "secondary"} >
                                    <span>שליחת פרטים</span>
                                </Button>

                                <Button onClick={() => this.props.sendPassmodal(false)} variant="secondary" >
                                    <span>ביטול</span>
                                </Button>

                            </div>
                        </div> }

                            

                        
                </Modal.Body>
            </Modal>
        )
    }
}
