import React, { Component } from 'react'
import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import convertDate from './../../../convertDateToDisplay'
import ModalDefaul from './../ModalDefaul';

import pencil from './../../../../img/otzarHaretz/system/icons/pencil.svg'

import offPic from './../../../../img/otzarHaretz/system/icons/off.svg';
import onPic from './../../../../img/otzarHaretz/system/icons/on.svg';

import ShowHeaderInfoClient from "./ShowHeaderInfoClient";
import { FormControl,Button } from 'react-bootstrap';

import ModalSendPassWord from './ModalSendPassWord';

export default class ShowDataClient extends Component {


    constructor(props) {
        super(props)
    
        let dataUser = this.props.client;

        this.state = {

            sendPassmodal : false,

            allTransactions : false,
            onOffUserResp : false,
            clientStatus : this.props.client.status === '1' ? true : false,

            editFields : false,
            editUserResp : false,

            firstName : dataUser.firstName ? dataUser.firstName : '',
            lastName : dataUser.lastName ? dataUser.lastName : '',
            phone : dataUser.phone ? dataUser.phone : '',
            email : dataUser.email ? dataUser.email : '',
            address : dataUser.fullAddress ? dataUser.fullAddress : '',
            comments : dataUser.comments ? dataUser.comments : '',
            pass : '',
            TotalPrice : dataUser.TotalPrice ? dataUser.TotalPrice : ''
        }
    }

    
    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
    
        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };
    
        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){
    
            //console.log(getData);
            
            if(url === 'onOffUser') {
                
                if(getData.ok) {
                    //console.log(getData);
                    this.props.reload();
                    this.setState({ clientStatus : !this.state.clientStatus });
                }
            }

            else if(url === 'editUserSystem') {
                
                if(getData.ok) {
                    this.setState({ editFields : false })
                    this.props.reload();
                }

            }
    
        }));
    }

    loadData = () => {

        this.sendtoAdmin('getAllUserTransactions','Transactions',{ clientId : this.props.client.id },'allTransactions');

    }


    componentDidMount() {

        if(!this.state.allTransactions) {
            this.loadData();
        }

    }

    Onoff = (id) => {

        this.sendtoAdmin('onOffUser','Users',{ clientId : id, currentStatus : this.state.clientStatus },'onOffUserResp');

        //console.log(id);
        
    }

    saveData = () => {
        
        let sendObj = {
            rowId : this.props.client.id,
            firstName : this.state.firstName,
            lastName : this.state.lastName,
            phone : this.state.phone,
            email : this.state.email,
            address : this.state.address,
            comments : this.state.comments,
            pass : this.state.pass,
            totalPrice : this.state.TotalPrice
        }

        this.sendtoAdmin('editUserSystem','Users',sendObj,'editUserResp');

    }
    
    

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }

    sendPassmodal = (actionState) => {
        
        this.setState({sendPassmodal : actionState});

    }
    


    render() {

        let dataUser = this.props.client;

        //console.log(dataUser);

        let Onoff = !this.state.clientStatus ? {text : 'לקוח לא פעיל', picture : offPic } :  {text : 'לקוח פעיל', picture : onPic };
        let flagPass;

        if(this.state.pass==='') {

            flagPass = true;

        } else if (this.state.pass.length >= 4 ) {

            flagPass = true;

        } else {

            flagPass = false;

        }

        let flagSave =  flagPass && this.state.firstName && this.state.lastName  && this.state.phone  && this.state.email
                        && this.state.address ? true : false;


        return (
            <div className='SelectedClient animate__animated animate__fadeIn clear' >

                {this.props.showHeader ? <ShowHeaderInfoClient dataUser={dataUser} /> : false }

                <div className="goBackBtnCont">
                    <button className="goBackBtn bounce" onClick={()=>this.props.clearUser()}>&laquo; חזור</button>
                </div>
                
                <div className={ this.state.clientStatus ? "tableDataUser clear" : "tableDataUser notActive clear" }>

                    <div className="RPrat colors">
                        <h2 className="systemTitle">
                            <span>פרטים אישים</span>
                            <Button className="btnSendPass" onClick={()=>this.sendPassmodal(true)} size="sm" variant="secondary" >שליחת פרטי משתמש</Button>
                        </h2>

                        <div className="buttons clear">
                            <button className='editUser' onClick={()=>this.setState({editFields : true})}>
                                <img src={pencil} alt='' />
                                <span>עריכת פרטים</span>
                            </button>

                            <button className='onOff' onClick={()=>this.Onoff(dataUser.id)}>
                                <img src={Onoff.picture} alt='' />
                                <span className={!this.state.clientStatus ? 'red' : ''} >{Onoff.text}</span>
                            </button>
                        </div>

                        <ul>
                            <li className="clear">
                                <span>שם משתמש</span>
                                <strong>{dataUser.user}</strong>
                            </li>

                            <li className="clear">
                                <span>מנוי (₪)</span>
                                {!this.state.editFields ? <strong>{this.state.TotalPrice}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='TotalPrice' value={this.state.TotalPrice} type="text"/> }
                            </li>

                            <li className="clear">
                                <span>שם פרטי</span>
                                {!this.state.editFields ? <strong>{this.state.firstName}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='firstName' value={this.state.firstName} type="text"/> }
                            </li>

                            <li className="clear">
                                <span>שם משפחה</span>
                                {!this.state.editFields ? <strong>{this.state.lastName}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='lastName' value={this.state.lastName} type="text"/>}
                            </li>

                            <li className="clear">
                                <span>טלפון</span>
                                {!this.state.editFields ? <strong>{this.state.phone}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='phone' value={this.state.phone} type="text"/>}
                            </li>

                            <li className="clear">
                                <span>מייל</span>
                                {!this.state.editFields ? <strong>{this.state.email}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='email' value={this.state.email} type="text"/>}
                            </li>

                            <li className="clear">
                                <span>כתובת</span>
                                {!this.state.editFields ? <strong>{this.state.address}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='address' value={this.state.address} type="text"/> }
                            </li>

                            <li className="clear">
                                <span>הערות</span>
                                {!this.state.editFields ? <strong>{this.state.comments}</strong> :
                                <FormControl onChange={this.handleChange} disabled={this.state.editFields ? false : true } name='comments' value={this.state.comments} type="text"/>}
                            </li>

                            {this.state.editFields ? <li className="clear">
                                <span className={!flagPass ? 'redClassInput' : ''} >סיסמה</span>
                                <FormControl className={!flagPass ? 'redClassInput' : ''} placeholder="החלפת סיסמה מעל 4 תווים" onChange={this.handleChange} name='pass' value={this.state.pass} type="text"/>
                            </li> : false }

                            {this.state.editFields ? <li className="clear">
                                <button className={flagSave ? 'send' : 'send sendDisabled'} disabled={ flagSave ? false : true } onClick={()=>this.saveData()}>שמור נתונים</button>
                            </li> : false }

                        </ul>
                    </div>

                    <div className="LPrat colors">
                        <h2 className="systemTitle">פרטי רכישות</h2>

                        {!this.state.selectedClient ? <div className='overflow'><div className="tableCont">

                                {this.state.allTransactions && this.state.allTransactions.data[0] ? <table className="tableClients">
                                    <thead>
                                        <tr>
                                            <th>שם הספק</th>
                                            <th>תאריך רכישה</th>
                                            <th>שעה</th>
                                            <th>קופה</th>
                                            <th>סכום רכישה</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {this.state.allTransactions.data.map(item => { if (true) {

                                            let datetimeTransaction = new Date(item.dateTransaction.toString().replace(/-/g, '/')); // IPHONE FIX
                                            var myTime = datetimeTransaction.getHours() + ':' + (datetimeTransaction.getMinutes()<10?'0':'') + datetimeTransaction.getMinutes() ;

                                            /* currentState={this.state} */
                                            return  <tr key={item.id} >
                                                        <td>{ item.name }</td>
                                                        <td>{ convertDate(item.dateTransaction) }</td>
                                                        <td>{ myTime }</td>
                                                        <td>{item.cashier}</td>
                                                        <td className="blueMoney"><small>₪</small>{parseFloat(item.money)}</td>
                                                    </tr>
                                        } else return false}) }
                                        
                                    </tbody>
                                </table> : <h2 style={{ color : 'red', textAlign : 'center', fontSize : '22px' }}>אין תוצאות</h2> }
                        </div></div>  : false }
                    </div>

                </div>


                {this.state.editUserResp &&  this.state.editUserResp.error ? 

                < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.editUserResp.error } } callBack={() => this.setState({ editUserResp : false })}  />

                : false }


                {this.state.editUserResp &&  this.state.editUserResp.ok ? 

                < ModalDefaul variant="" params={ {title: 'תודה', text : this.state.editUserResp.ok } } callBack={() => this.setState({ editUserResp : false })}  />

                : false }


                {this.state.sendPassmodal ?

                <ModalSendPassWord modalShow={true} isClient={true} dataUser = { this.props.client } sendPassmodal = { (action)=>this.sendPassmodal(action) } />

                : false }

            </div>
        )
    }
}
