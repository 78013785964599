import React, { Component } from 'react'
import SuppliersList from "./SuppliersList"

export default class SuppliersSystem extends Component {
    render() {
        return (
            <div className='HomePage animate__animated animate__fadeIn'>

                <SuppliersList limit={7} search={true} />

            </div>
        )
    }
}
