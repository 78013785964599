import React, { Component } from 'react'
import { FormControl, Button } from 'react-bootstrap';
import addClient from '../../../../img/otzarHaretz/system/icons/addClient.svg'
import ModalDefaul from './../ModalDefaul';

import { map } from 'lodash'

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";

export default class AddSupplier extends Component {

    constructor(props) {
        super(props)

        //console.log(props);
    
        this.state = {

            name : '',
            agent : '',
            phone : '',
            email : '',
            tz : '',
            address : '',
            comments : '',
            ownerName : '',
            marketerId : '1',
            
            insertClient : false,
            loading : false
        }
    }

    sendtoAdmin = (url,controller,objectToSend = {}, stateNameResponse = false) => {

        let setStateName;
    
        if(stateNameResponse) {
            setStateName = stateNameResponse;
        } else {
            setStateName = 'data';
        };
    
        let getData = getDataFromApi(url,objectToSend,controller);
        getData.then(function(response) {return response;}).then(getData =>this.setState({ [setStateName] : getData,  loading : false }, function(){
    
            //console.log(getData);
            if(url === 'manualInsertSupplier') {

                if(getData.response.addLeadtoDb && !getData.response.error ) {

                    this.props.reload();

                    this.setState({
                        name : '',
                        agent : '',
                        phone : '',
                        email : '',
                        tz : '',
                        address : '',
                        comments : '',
                        ownerName : '',
                        marketerId : '1'
                    });
                } 

            }
    
        }));
    }



    addClient = () => {

        this.setState({loading : true});

        
        this.sendtoAdmin('manualInsertSupplier','Suppliers', this.state ,'insertClient');
        
    }
    
    


    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value});
    }

    handleSelect = (event) => {

        
        this.setState({ [event.target.name]: event.target.value},function(){
            //console.log(this.state)
        });
    
    }

    render() {


        let flagBtn =   !this.state.loading && this.state.name && this.state.agent && this.state.phone
                        && this.state.tz && this.state.ownerName && this.state.address 
                        ? true : false;
        

        return (
            <div className="AddClient AddSupplier">
                <h2>הוספת ספק</h2>
                <div className="inputs">
                    <div className="row">

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='שם ספק' name='name' value={this.state.name} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='איש קשר' name='ownerName' value={this.state.ownerName} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='אימייל' name='email' value={this.state.email} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='טלפון' name='phone' value={this.state.phone} />
                        </div>

                    </div>

                    <div className="row">

                        

                        <div className="col-6">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='כתובת' name='address' value={this.state.address} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='סוכן' name='agent' value={this.state.agent} />
                        </div>

                        <div className="col-3">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='תעודת זהות' name='tz' value={this.state.tz} />
                        </div>
                        
                    </div>

                    <div className="row">

                        <div className="col-6">
                            <FormControl onChange={this.handleChange} type="text"
                            placeholder='הערות' name='comments' value={this.state.comments} />
                        </div>

                        <div className="col-3">
                            <FormControl
                                style={{width : '100%'}}
                                as='select'
                                /* 'inputPrepend customSelect' */
                                className='inputPrepend customSelect'
                                onChange={this.handleSelect} 
                                value={this.state.marketerId}
                                name="marketerId"
                            >
                            
                            {   map(this.props.marketers, item => {
                    
                                return <option key={ item.id } value={ item.id }>{item.name}</option>
                            
                                })
                            }

                            </FormControl>
                        </div>

                        <div className="col-3">

                            <Button className='addClientBtn' style={{width : '100%'}} onClick={this.addClient} size="" disabled={ flagBtn ? false : true } variant={flagBtn ? "success" : "secondary" } ><img src={addClient} alt='' />הוספת ספק</Button>

                        </div>

                    </div>

                </div>

                {this.state.insertClient &&  this.state.insertClient.error ? 

                    < ModalDefaul variant="error" params={ {title: 'שגיאה', text : this.state.insertClient.error } } callBack={() => this.setState({ insertClient : false })}  />

                    : false }
            </div>
        )
    }
}
