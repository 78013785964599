import { RestUrls } from "./../Urls"

export default async function getDataFromApi (url,objectToSend, controller = 'first') {

    const API =  RestUrls.baseApiUrl1 + controller + '/' + url;

    let userJ = localStorage.getItem('user-info') ? JSON.parse(localStorage.getItem('user-info')) : '';
    let user = userJ.data ? userJ.data : '';
    
    //console.log(user);

    let userPass = { 
            userId : user.id ? user.id : '',
            userName : user.username,
            userCredential : user.userCredential,
            token : user.token,
            siteCode :  RestUrls.Code
        };

    let items = {...userPass, ...objectToSend}

    //console.log(items);

    try {
        const res = await fetch(API, {
            method: 'POST',
            headers: {
            "Content-Type" : 'application/json',
            "Accept" : 'application/json'
            },
            body: JSON.stringify(items)
        });


        const data = await res.json();

        if(data === 'unauthorized') {
            localStorage.setItem('user-info','');
            window.location.reload();
        } 
        
        else if(data) {
            //console.log(data);
            return data;
        }
    }

    catch (e) {
        //localStorage.setItem('user-info','');
        alert ('שגיאה');
        //window.location.reload();
    }

}